import React, { Component } from 'react'
import { Table, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./chart_list.scss"
export default class chart_list extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chart_list: [],
            dataList: {},
            needRefresh: false,
            columns: [
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                    // width: 30,
                    // fixed: 'left',
                },
                {
                    title: '值',
                    dataIndex: 'num',
                    key: 'num',
                    // width: 20,
                },
                {
                    title: '占比',
                    dataIndex: 'percent',
                    key: 'percent',
                    // width: 30,
                },
            ]
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                question_type: props.questionType
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {

            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitColumns() {
        let isConsumer = this.props.isConsumer || false
        let questionType = this.props.questionType || ""
        if (isConsumer) {
            let columns = [
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                    // width: 30,
                    // fixed: 'left',
                },
                {
                    title: '占比',
                    dataIndex: 'percent',
                    key: 'percent',
                    // width: 30,
                },
            ]
            this.setState({
                columns: columns
            })
        }

        if (questionType === 6) { // 6-比重题
            let columns = [
                {
                    title: '选项',
                    dataIndex: 'name',
                    key: 'name',
                    // width: 30,
                    // fixed: 'left',
                },
                {
                    title: '平均比重',
                    dataIndex: 'num',
                    key: 'num',
                    // width: 30,
                },
                {
                    title: '标准差',
                    dataIndex: 'value_std',
                    key: 'value_std',
                    // width: 30,
                },
                {
                    title: '回答人数',
                    dataIndex: 'value_count',
                    key: 'value_count',
                    // width: 30,
                },
            ]
            this.setState({
                columns: columns
            })

        } else if (questionType === 8) { // 8-矩阵单选题
            let columns = []
            let title_width = 200;
            if (this.props.isMaxChart) {
                title_width = 400;
            }
            columns.push({
                title: '选项',
                dataIndex: 'name',
                key: 'name',
                width: title_width,
                fixed: 'left',
            })

            for (let i = 0; i < this.props.dataList.seriesBarList.length; i++) {
                columns.push({
                    title: this.props.dataList.seriesBarList[i].name,
                    dataIndex: 'num' + i,
                    key: 'num' + i,
                    width: 90,
                })
            }
            columns.push({
                title: '回答人数',
                dataIndex: 'sum',
                key: 'sum',
                width: 90,

            })

            this.setState({
                columns: columns
            })

        }
    }

    toInitChart() {
        this.toInitColumns()
        if (this.props.questionType === 5) {
            console.log(this.props.dataList)
        }
        let sum = this.props.dataList.sum
        let chart_list = []
        if (this.props.questionType === 1) {
            // 单选题

            // check 是否需要计算top值等
            let needTop = false;
            let needJar = false;
            let topX = [];

            if (this.props.dataList.data.length > 3) {
                if (this.props.dataList.is_jar === 1) {
                    needJar = true;
                }
                if (this.props.dataList.data[0].option_position !== "") {
                    needTop = true;
                    if (this.props.dataList.data.length == 7) {
                        topX = ['top1', 'top3']
                    } else if (this.props.dataList.data.length == 5) {
                        topX = ['top1', 'top2']
                    } else {
                        topX = ['top1']
                    }

                }
            }

            if (needTop) {
                let topKObj = {};
                let cur_count = 0;
                let total_value = 0;
                let total_count = this.props.dataList.sum;
                let temp_chart_list = [];
                for (let i = 0; i < this.props.dataList.data.length; i++) {
                    cur_count += this.props.dataList.data[i].value
                    total_value += this.props.dataList.data[i].value * this.props.dataList.data[i].option_position
                    topKObj[`top${Number(i) + 1}`] = [(cur_count / total_count * 100).toFixed(2), cur_count];
                    let obj = { name: this.props.dataList.data[i].name, percent: "", num: this.props.dataList.data[i].value || 0, id: "chart_list_" + i, value_std: this.props.dataList.data[i].value_std || 0, value_count: this.props.dataList.data[i].value_count || 0 }
                    let percent = (Number(obj.num / sum) * 100).toFixed(2) + "%"
                    obj.percent = percent
                    temp_chart_list.push(obj)

                }

                // Mean
                let mean = (total_value / total_count).toFixed(2);
                let obj = { name: "均值", percent: "-", num: mean, id: "chart_list_mean" }
                chart_list.push(obj)


                // Top K
                for (let k = 0; k < topX.length; k++) {
                    let obj = { name: topX[k], num: topKObj[topX[k]][1], percent: topKObj[topX[k]][0] + "%", id: "chart_list_" + k }
                    chart_list.push(obj)
                }

                // 原始数据
                chart_list = chart_list.concat(temp_chart_list)


            }
            else {
                // TODO: 先不管JAR的情况，无所谓，也就是取中间的值
                for (let i = 0; i < this.props.dataList.data.length; i++) {
                    let obj = { name: this.props.dataList.data[i].name, percent: "", num: this.props.dataList.data[i].value || 0, id: "chart_list_" + i, value_std: this.props.dataList.data[i].value_std || 0, value_count: this.props.dataList.data[i].value_count || 0 }
                    let percent = (Number(obj.num / sum) * 100).toFixed(2) + "%"
                    obj.percent = percent
                    chart_list.push(obj)
                }

            }


        }
        else if (this.props.questionType === 2) {
            // 多选题
            for (let i = 0; i < this.props.dataList.data.length; i++) {
                let obj = { name: this.props.dataList.x_axis[i], percent: "", num: this.props.dataList.data[i] || 0, id: "chart_list_" + i }
                let percent = (Number(obj.num / sum) * 100).toFixed(2) + "%"
                obj.percent = percent
                chart_list.push(obj)
            }
        }

        else if (this.props.questionType === 5 || this.props.questionType === 7) {
            // 打分题

            let score_sum = 0;
            let count = 0;
            let topKObj = {};
            let temp_chart_list = [];

            for (let i = 0; i < this.props.dataList.data.length; i++) {
                score_sum += this.props.dataList.data[i] * this.props.dataList.x_axis[i]
                count += this.props.dataList.data[i]

            }
            let tmpsum = 0;
            let reversed = this.props.dataList.data.slice().reverse();
            for (let j = 0; j < reversed.length; j++) {
                tmpsum += reversed[j];
                topKObj[`top${Number(j) + 1}`] = [(tmpsum / count * 100).toFixed(2), tmpsum];
                let obj = { name: this.props.dataList.x_axis[j], percent: (this.props.dataList.data[j] / count * 100).toFixed(2) + "%", num: this.props.dataList.data[j] || 0, id: "chart_list_" + j }
                temp_chart_list.push(obj)
            }

            // Mean
            let mean = (score_sum / count).toFixed(2);
            let obj = { name: "均值", percent: "-", num: mean, id: "chart_list_mean" }
            chart_list.push(obj)

            // Top K
            let topX = [];
            if (this.props.dataList.data.length >= 7) {
                topX = ['top1', 'top3']
            } else if (this.props.dataList.data.length >= 5) {
                topX = ['top1', 'top2']
            } else {
                topX = ['top1']
            }
            for (let k = 0; k < topX.length; k++) {
                let obj = { name: topX[k], percent: topKObj[topX[k]][0] + "%", num: topKObj[topX[k]][1], id: "chart_list_" + k }
                chart_list.push(obj)
            }


            temp_chart_list.reverse();
            chart_list = chart_list.concat(temp_chart_list);

        } else if (this.props.questionType === 8) { // 8-矩阵单选题

            for (let i = 0; i < this.props.dataList.xAxisList.length; i++) {
                // 循环每个选项
                let option_sum = this.props.dataList.xAxisSum[i];
                let obj = {};
                obj.name = this.props.dataList.xAxisList[i];
                obj.sum = option_sum;
                for (let j = 0; j < this.props.dataList.seriesBarList.length; j++) {
                    // 循环每个矩阵
                    obj[`num${j}`] = (this.props.dataList.seriesBarList[j].data[i] / option_sum * 100).toFixed(2) + "%";
                }
                chart_list.push(obj);
            }


        } else {
            if (this.props.questionType === 20) {
                // 20 - Heatmap 题型
                let obj = { name: "完成人数", percent: "100%", num: this.props.dataList.sum, id: "chart_list_people_count" }
                chart_list.push(obj)
            }
            for (let i = 0; i < this.props.dataList.data.length; i++) {
                let obj = { name: this.props.dataList.data[i].name, percent: "", num: this.props.dataList.data[i].value || 0, id: "chart_list_" + i, value_std: this.props.dataList.data[i].value_std || 0, value_count: this.props.dataList.data[i].value_count || 0 }
                let percent = (Number(obj.num / sum) * 100).toFixed(2) + "%"
                obj.percent = percent
                chart_list.push(obj)
            }
        }

        this.setState({
            chart_list: chart_list,
        })
    }
    showPagination() {
        if (this.state.chart_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    render() {
        return (
            <div className="chart-list-container">
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.chart_list}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 340 }} />
                </ConfigProvider>
            </div>
        )
    }
}
