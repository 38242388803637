import React, {Component} from 'react'
import './brand_info.scss';
import {Button, ConfigProvider, Image, Input, message, Radio, Upload} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import edit_icon from '../../images/login/edit_icon.png';
import edit_icon_active from '../../images/login/edit_icon_active.png';
import edit_icon_ok from '../../images/login/edit_icon_ok.png';
import check_icon from '../../images/login/check_icon.png';
import check_icon_active from '../../images/login/check_icon_active.png';
import check_icon_active_nor from '../../images/login/check_icon_active_nor.png';
import upload_close_img from '../../images/upload_close.png'
import upload_icon from '../../images/login/upload_icon.png'
import code_icon from '../../images/login/code_icon.png';
import XmSelect from '../../components/Select/xm_select';
import {desired_info_list} from '../User_info/Values/user_data'

import {getSmsVerification} from '../../api/token'

export default class brand_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code_button_disabled: false,//禁止
            code_button_loading: false,//加载
            code_button_text: '获取验证码',//验证码文字
            desired_info_list: desired_info_list,
            modelContent: "确定删除该图片吗？",
            showModel: false,
            modelTitle: "删除提示",
            brand_info: {
                first_name: "",//姓名
                phone: "",//手机
                brand: "",//品牌
                company: "",//公司
                department: "",//部门
                position: "",//职位
                // photo_verification: [{ filename: "", file: "" }]
                photo_verification: [],//照片
                desired_info: [],
                comment: "",//评论
                sms_code: "",
                invite_code: "",
                invite_type: null, // 客户来源 1 新人注册 2老客户 3 定向邀请
                inviter: "", // 邀请人
            },
            invite_type_list: [
                {key: '1', value: "新人注册"},
                {key: '2', value: "老客户"},
                {key: '3', value: "定向邀请"},
            ],
            has_invite_code: false,
            login_type: "", // 登录是第一个方式 还是第二个方式
            user_status: "",// 1 新用户 2 审核中 3 老用户
        }

    }

    setBrandInfo(obj) {
        let {login_type, user_status, brand_info} = obj;
        let desired_info = brand_info.desired_info
        let desired_info_list = JSON.parse(JSON.stringify(this.state.desired_info_list))
        for (let i = 0; i < desired_info.length; i++) {
            let index = parseInt(desired_info[i]) - 1
            desired_info_list[index].is_active = true
        }
        this.setState({
            login_type: login_type,
            user_status: user_status,
            brand_info: brand_info,
            desired_info_list: desired_info_list,
            code_button_disabled: false,
            code_button_loading: false,
            code_button_text: '获取验证码'
        })
    }

    tags_confirm() {
        if (!this.validateRequired()) return

        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        for (let i = 0; i < this.state.desired_info_list.length; i++) {
            if (this.state.desired_info_list[i].is_active) {
                brand_info.desired_info.push(i + 1)
            }
        }

        this.props.handleBrandInfo({
            is_confirm: true,
            // show_brand_info: false,
            brand_info: brand_info
        })
    }

    tags_close() {
        this.props.handleBrandInfo({
            is_confirm: false,
            show_brand_info: false,
        })
    }

    // 验证信息渠道
    validateDesiredInfo() {
        let flag = false
        for (let i = 0; i < this.state.desired_info_list.length; i++) {
            if (this.state.desired_info_list[i].is_active) {
                flag = true
            }
        }
        return flag
    }

    // 是否必填的
    validateRequired() {
        let isFilled = true;
        if (!this.state.brand_info.first_name) {
            message.warning(`姓名不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (this.state.login_type === "wx_login" && Number(this.state.user_status) === 1) {
            // 微信扫码登陆
            if (!this.state.brand_info.phone) {
                message.warning(`手机不可为空`, 2, () => {
                })
                isFilled = false;
            } else if (!this.state.brand_info.sms_code) {
                message.warning(`验证码不可为空`, 2, () => {
                })
                isFilled = false;
            }
        }
        if (!isFilled || (this.state.has_invite_code && this.state.brand_info.invite_code)) {
            return isFilled;
        }
        if (this.state.has_invite_code && !this.state.brand_info.invite_code) {
            message.warning(`邀请码不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.brand) {
            message.warning(`品牌不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.company) {
            message.warning(`公司不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.department) {
            message.warning(`部门不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.position) {
            message.warning(`职位不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.photo_verification.length) {
            message.warning(`上传证明不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.validateDesiredInfo()) {
            message.warning(`信息渠道不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (!this.state.brand_info.invite_type) {
            message.warning(`您的来源不可为空`, 2, () => {
            })
            isFilled = false;
        } else if (this.state.brand_info.invite_type === '3' && !this.state.brand_info.inviter) {
            message.warning(`您的邀请人不可为空`, 2, () => {
            })
            isFilled = false;
        }
        return isFilled
    }

    // 输入输入框
    handleInput(key, event) {
        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        brand_info[key] = event.target.value
        this.setState({
            brand_info: brand_info
        })
    }

    // 切换渠道信息checkbox
    handleCheckbox(index) {
        let desired_info_list = JSON.parse(JSON.stringify(this.state.desired_info_list))
        desired_info_list[index].is_active = !desired_info_list[index].is_active
        this.setState({
            desired_info_list: desired_info_list
        })
    }

    // 只能输入数字的字符
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^[^\d]+/g, '')
        // event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }

    // 上传图片
    handleUpload(args) {
        let _this = this//烦人的this
        let filename = args.file.name || ""
        let type = args.file.type
        if (type !== "image/jpeg" && type !== "image/png" && type !== "image/jpg") {
            message.warning('图片格式只能是jpg、jpeg、png', 2, () => {
            })
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(args.file);
        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        reader.onload = function (event) {//读取数据时触发load
            let size = Number(args.file.size) / 1024 / 1024
            if (size > 2) {
                message.warning('图片大小不可超过2MB', 2, () => {
                })
                return
            }
            let imgs = this.result
            let obj = {
                file: imgs,
                filename: filename
            }
            brand_info.photo_verification.push(obj)

            _this.setState({
                brand_info: brand_info
            })
        };
    }

    //图片设置为空
    handleDeletePhoto(index) {
        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        brand_info.photo_verification.splice(index, 1)
        this.setState({
            brand_info: brand_info,
        })
    }

    // 判断手机号
    isPhone(val) {
        return /^1[0-9]{10}$/.test(val);
    }

    // 处理不可输入
    isDisabled(t) {
        if (t === "phone") {
            if (this.state.login_type === "phone_login") {
                return true;
            }
        }
        if (Number(this.state.user_status) === 2) {
            return true;
        }
        return false;
    }

    // 获取短信验证码
    sendCode(t) {
        if (!this.isPhone(this.state.brand_info.phone)) {
            message.warning('请输入正确的手机号', 2, () => {
            })
            return
        }
        this.setState({
            code_button_loading: true,
            code_button_text: "发送中",
        }, () => {
            let expire = 180
            // if (t === "phone_login") {
            //     expire = 60
            // }
            this.getSmsVerification(expire)
        })
    }

    // 手机获取验证码（登录）
    async getSmsVerification(expire) {//验证并成功跳转
        try {
            const response = await getSmsVerification({
                phone_number: this.state.brand_info.phone,
                expire: expire
                // phone_number: "17717281798",
            })
            this.countDown(expire)
            return null;
        } catch (error) {
            this.setState({
                code_button_loading: false,
                code_button_text: "重新获取"
            })
            message.error('请重新获取验证码!')
            throw error;
        }
    }

    // 倒计时
    countDown = (expire) => {
        // 定时器
        let timer = null
        // 倒计时时间
        let sec = expire
        //修改状态
        this.setState({
            code_button_loading: false,
            code_button_disabled: true,
            code_button_text: `${sec}s`
        })

        timer = setInterval(() => {
            sec--;
            if (sec <= 0) {
                this.setState({
                    code_button_text: `重新获取`,
                    code_button_disabled: false,
                })
                clearInterval(timer);
                return false
            }
            this.setState({
                code_button_text: `${sec}s`
            })
        }, 1000)
    }
    // 切换radio
    onRadioChange = (event) => {
        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        if (!event.target.value) {
            brand_info.invite_code = "" // 无邀请码
        }
        this.setState({
            has_invite_code: event.target.value,
            brand_info: brand_info
        })
    }

    // 下拉框变换
    onSelectSourceChange(e) {
        let brand_info = JSON.parse(JSON.stringify(this.state.brand_info))
        if (e === '1' || e === '2') {
            brand_info.inviter = ""
        }
        brand_info.invite_type = e
        this.setState({
            brand_info: brand_info
        })
    }

    // 平台服务协议
    clickUserAgreement = () => {
        let tmpwindow = window.open('_blank');
        tmpwindow.location = '/user_agreement'; // 可以打开新的地址
    }
    // 隐私协议
    clickPrivacyAgreement = () => {
        let tmpwindow = window.open('_blank');
        tmpwindow.location = '/privacy_agreement'; // 可以打开新的地址
    }

    render() {
        const suffix_edit = (
            <img src={edit_icon} alt="" className='item-icon'/>
        )
        const suffix_edit_active = (
            <img src={edit_icon_active} alt="" className='item-icon'/>
        )
        const suffix_edit_ok = (
            <img src={edit_icon_ok} alt="" className='item-icon'/>
        )
        const prefix_2 = (
            <img src={code_icon} alt="" className='evaluation-icon-search'/>
        )
        return (
            <div className='brand-info-popup-container'>
                <div className='brand-info-popup-bg'>
                    <div className='brand-info-popup-main'>
                        <div className='brand-info-popup-top'>
                            <div className='popup-top-title'>用户信息认证</div>
                            {/* <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                            </div> */}
                        </div>
                        <div className='brand-info-popup-content'>
                            <div className="brand-user-wrapper">
                                <span className='brand-user-name'>尊敬的心盒共创用户</span>
                                {/* <span className='brand-user-id'>ID：1356298888</span>
                                <span className='brand-user-copy'>复制</span> */}
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    <span className='item-sign'>*</span>
                                    姓名
                                </div>
                                <div className="item-input-wrapper">
                                    <Input
                                        suffix={this.state.brand_info.first_name.length ? suffix_edit_ok : suffix_edit}
                                        type="text" className="item-input" value={this.state.brand_info.first_name}
                                        placeholder="请输入您的姓名…"
                                        onChange={this.handleInput.bind(this, "first_name")}
                                        disabled={this.isDisabled.call(this)}/>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    <span className='item-sign'>*</span>
                                    手机
                                </div>
                                <div className="item-input-wrapper">
                                    <Input
                                        suffix={this.state.brand_info.phone.length && this.isPhone(this.state.brand_info.phone) ? suffix_edit_ok : suffix_edit}
                                        type="text" className="item-input" value={this.state.brand_info.phone}
                                        placeholder="请输入您的联系方式…"
                                        onChange={this.handleInput.bind(this, "phone")}
                                        onInput={this.validateNumber.bind(this)}
                                        disabled={this.isDisabled.call(this, "phone")}/>
                                </div>
                            </div>
                            {
                                this.state.login_type === "wx_login" && Number(this.state.user_status) === 1 ?
                                    <div className="card-item">
                                        <div className="item-name">
                                            <span className='item-sign'>*</span>
                                            验证码
                                        </div>
                                        <div className="item-input-wrapper">
                                            <Input.Group compact>
                                                <Input prefix={prefix_2} className="sms-input"
                                                       placeholder="请输入短信验证码"
                                                       value={this.state.brand_info.sms_code}
                                                       onChange={this.handleInput.bind(this, "sms_code")} maxLength={6}
                                                       onInput={this.validateNumber.bind(this)}/>
                                                <Button className="sms-btn"
                                                        onClick={this.sendCode.bind(this, "wx_login")}
                                                        disabled={this.state.code_button_disabled}
                                                        loading={this.state.code_button_loading}>{this.state.code_button_text}</Button>
                                            </Input.Group>
                                        </div>
                                    </div> : ""
                            }
                            <div className="card-item">
                                <div className="item-name">
                                    <span className='item-sign'>*</span>
                                    是否有邀请码
                                </div>
                                <div className="item-input-wrapper">
                                    <Radio.Group onChange={this.onRadioChange} value={this.state.has_invite_code}
                                                 disabled={this.isDisabled.call(this)}>
                                        <Radio value={true}>有</Radio>
                                        <Radio value={false}>否</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {
                                this.state.has_invite_code ? <div className="card-item">
                                    <div className="item-name">
                                        <span className='item-sign'>*</span>
                                        邀请码
                                    </div>
                                    <div className="item-input-wrapper">
                                        <Input
                                            suffix={this.state.brand_info.invite_code?.length ? suffix_edit_ok : suffix_edit}
                                            type="text" className="item-input" value={this.state.brand_info.invite_code}
                                            placeholder="请输入您的邀请码…"
                                            onChange={this.handleInput.bind(this, "invite_code")}
                                            disabled={this.isDisabled.call(this)}/>
                                    </div>
                                </div> : ""
                            }
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    品牌
                                </div>
                                <div className="item-input-wrapper">
                                    <Input suffix={this.state.brand_info.brand.length ? suffix_edit_ok : suffix_edit}
                                           type="text" className="item-input" value={this.state.brand_info.brand}
                                           placeholder="请输入您的品牌名称…"
                                           onChange={this.handleInput.bind(this, "brand")}
                                           disabled={this.isDisabled.call(this)}/>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    公司
                                </div>
                                <div className="item-input-wrapper">
                                    <Input suffix={this.state.brand_info.company.length ? suffix_edit_ok : suffix_edit}
                                           type="text" className="item-input" value={this.state.brand_info.company}
                                           placeholder="请输入您的公司名称…"
                                           onChange={this.handleInput.bind(this, "company")}
                                           disabled={this.isDisabled.call(this)}/>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    部门
                                </div>
                                <div className="item-input-row">
                                    <div className="item-input-wrapper">
                                        <Input
                                            suffix={this.state.brand_info.department.length ? suffix_edit_ok : suffix_edit}
                                            type="text" className="item-input" value={this.state.brand_info.department}
                                            placeholder="请输入您所在的部门…"
                                            onChange={this.handleInput.bind(this, "department")}
                                            disabled={this.isDisabled.call(this)}/>
                                    </div>
                                    <div className="item-input-wrapper">
                                        <Input
                                            suffix={this.state.brand_info.position.length ? suffix_edit_ok : suffix_edit}
                                            type="text" className="item-input" value={this.state.brand_info.position}
                                            placeholder="请输入您的职位名称…"
                                            onChange={this.handleInput.bind(this, "position")}
                                            disabled={this.isDisabled.call(this)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    上传证明（例：营业执照、工牌、名片、账号截图等）
                                </div>
                                <div className="card-image-wrapper">
                                    {
                                        this.state.brand_info.photo_verification.length > 0 ?
                                            <>
                                                {
                                                    this.state.brand_info.photo_verification.map((item, index) => {
                                                        return <div className='card-image' key={index}>
                                                            <ConfigProvider locale={zhCN}>
                                                                <Image src={item.file} alt="" className="show-pic"
                                                                       width={76} height={76}/>
                                                            </ConfigProvider>
                                                            {
                                                                this.isDisabled.call(this) ? "" :
                                                                    <img src={upload_close_img} alt=""
                                                                         className="upload-close-img"
                                                                         onClick={this.handleDeletePhoto.bind(this, index)}/>
                                                            }

                                                        </div>
                                                    })
                                                }
                                            </>
                                            : ""
                                    }
                                    {
                                        this.isDisabled.call(this) || this.state.brand_info.photo_verification.length >= 5 ? "" :
                                            <div className='card-image-2'>
                                                <Upload
                                                    showUploadList={false}
                                                    customRequest={this.handleUpload.bind(this)}
                                                    onRemove={this.handleRemove}
                                                >
                                                    <div className="upload-pic-wrapper">
                                                        <img src={upload_icon} alt="" className="upload-pic"/>
                                                        <div className="upload-pic-text">上传图片</div>
                                                    </div>

                                                </Upload>

                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    您想了解哪些信息？（可多选）
                                </div>
                                <div className="card-check-wrapper">
                                    {
                                        this.state.desired_info_list.map((item, index) => {
                                            return <div className="card-check" key={index}>
                                                {
                                                    this.isDisabled.call(this) ?
                                                        <div className='checkbox checkbox-disabled'>
                                                            {
                                                                item.is_active ?
                                                                    <img src={check_icon_active_nor} alt=""/> :
                                                                    <img src={check_icon} alt=""/>
                                                            }
                                                        </div> :
                                                        <div className='checkbox'
                                                             onClick={this.handleCheckbox.bind(this, index)}>
                                                            {
                                                                item.is_active ? <img src={check_icon_active} alt=""/> :
                                                                    <img src={check_icon} alt=""/>
                                                            }
                                                        </div>
                                                }

                                                <div className='check-text'>{item.val}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="item-name">
                                    {
                                        this.state.has_invite_code ? <span className='item-sign'></span> :
                                            <span className='item-sign'>*</span>
                                    }
                                    您的来源
                                </div>
                                <div className="item-input-row">
                                    <div className="item-input-wrapper">
                                        <XmSelect options={this.state.invite_type_list}
                                                  value={this.state.brand_info.invite_type}
                                                  onChange={(e) => {
                                                      this.onSelectSourceChange(e)
                                                  }} placeholder="请选择您的来源..."
                                                  disabled={this.isDisabled.call(this)}></XmSelect>
                                    </div>
                                    <div className="item-input-wrapper">
                                        {
                                            this.state.brand_info.invite_type === '3' ? <div>
                                                <Input
                                                    suffix={this.state.brand_info.inviter?.length ? suffix_edit_ok : suffix_edit}
                                                    type="text" className="item-input"
                                                    value={this.state.brand_info.inviter} placeholder="请输入您的邀请人"
                                                    onChange={this.handleInput.bind(this, "inviter")}
                                                    disabled={this.isDisabled.call(this)}/>
                                            </div> : <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-item">
                                <textarea rows={4} placeholder='请描述您的详细需求（选填)' type='text'
                                          className={this.isDisabled.call(this) ? "card-textarea-disabled" : ""}
                                          value={this.state.brand_info.comment}
                                          onChange={this.handleInput.bind(this, "comment")}
                                          disabled={this.isDisabled.call(this)}/>
                            </div>
                            <div className="info-remark">
                                注：上海心盒科技有限公司收集上述信息目的在于初步审核合作，公司合法资质。如超出上述
                                目的，将再次征求您同意，请您理解并知悉，当您提交认证信息后即表示您已阅读并接受
                                <span className="info-remark-blue" onClick={this.clickUserAgreement}>《用户协议》</span>
                                和 <span className="info-remark-blue"
                                         onClick={this.clickPrivacyAgreement}>《个人信息保护政策》</span>
                            </div>
                        </div>
                        <div className='tag-btn'>
                            {
                                this.isDisabled.call(this) ?
                                    <div className="tag-btn-pending">正在审核中...</div> :
                                    <div onClick={this.tags_confirm.bind(this)}>提交</div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
