import React, { Component } from "react";
import "./question_config.scss";
import {
  questionType,
  getQuestionTypeValue,
  getQuestionType,
  getQuestionTypeName,
} from "../Question_rules/question_type_rule";
import XmTip from "../../../Xm_tip/xm_tip";
import cur_icon_select from "../../../../images/cur_icon_select.png";
import cur_icon_off from "../../../../images/cur_icon_off.png";
import cur_icon_on from "../../../../images/cur_icon_on.png";
import XmSelect from "../../../Select/xm_select";
import { Select, message } from "antd";
import { Input } from "antd";
const { Option } = Select;
export default class question_config extends Component {
  constructor(props) {
    super(props);
    this.is_option_random_list = [
      "single",
      "multiple",
      "sort",
      "proportion",
      "matrix_score",
      "matrix_nps",
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_psm",
    ];
    this.is_option_active_list = [
      "single",
      "multiple",
      "sort",
      "proportion",
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_score",
      "matrix_nps",
      "matrix_psm",
    ];
    this.has_content_list = [
      "single",
      "multiple",
      "sort",
      "proportion",
      "matrix_single",
      "matrix_multiple",
      "matrix_blank",
      "matrix_score",
      "matrix_nps",
      "matrix_psm",
    ];
    this.has_topx_list = ["single"];
    this.has_mutex_list = ["multiple","sort"];
    this.is_other_list = ["single", "multiple", "sort"];
    this.has_option_list = ["multiple", "matrix_multiple", "enumeration", "heatmap", "sort"]; //最多最少选项
    this.has_option_list_1 = ["multiple", "matrix_multiple", "enumeration", "heatmap"]; //最多最少选项
    this.has_shelf_list = ["multiple"]
    this.has_value_list = ["score", "nps", "matrix_score", "matrix_nps", "max_diff"]; // 最大小值 名称
    this.has_fixed_list = [11, 12, 13]; // 最大小值 名称
    this.state = {
      name: "question_config",
      showTipPopup: false, // 是否展示提示
      tip_popup_msg: "", // 提示文字信息
      next_selected_question_type: "", // 将select的题目类型
      option_list: [],
      beforeUpdateMin: 0,
      beforeUpdateMax: 10,
      currentQuestionIndex: -1,
      currentQuestionKey: "",
      dataList: {},
      question_type_val: "single",
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev) ||
      JSON.stringify(props.option_list) !==
      JSON.stringify(state.option_list_prev)
    ) {
      return {
        needRefresh: true,
        currentQuestionIndex: props.currentQuestionIndex, //问题数组index
        dataList: props.dataList || {}, //question
        dataList_prev: props.dataList,
        option_list: props.option_list || [],
        option_list_prev: props.option_list,
        currentQuestionKey: props.dataList ? props.dataList.question_type : "",
        beforeUpdateMin: props.dataList
          ? props.dataList.min_value
          : state.beforeUpdateMin,
        beforeUpdateMax: props.dataList
          ? props.dataList.max_value
          : state.beforeUpdateMax,
        question_type_val: props.dataList
          ? getQuestionType(props.dataList.question_type)
          : state.question_type_val,
        // question_type_val: props.dataList ? getQuestionType(props.dataList.question_type) : state.question.question_type
      };
    }
    return null;
  }
  componentDidMount() {
    this._isMounted = true;
    // if (this._isMounted) {
    //     this.toInitChart()
    //     setTimeout(() => {
    //         this.toInitFoot()
    //     })
    // }
  }
  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      // this.toInitChart()
      // setTimeout(() => {
      //     this.toInitFoot()
      // })
      this.setState({
        needRefresh: false,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = () => { };
  }
  // 切换switch
  handleSwitchs(key) {
    this.props.handleSwitch(key, this.state.currentQuestionIndex);
  }
  //问卷设置
  logic_settings() {
    this.props.logicSetting();
  }
  // 计算分数设置
  calculation_settings() {
    this.props.calculationSetting();
  }
  //处理input
  handleInput(key, event) {
    if (event.target.value === "" && key === "max_name" && this.state.question_type_val === "multiple") {
      // 如果是多选题，最大值可以为空，不需要额外处理，
      // TODO: 但是这里需要考虑做其他的验证
    }

    else if (event.target.value === "") {
      if (key === "min_name") {
        event.target.value = "非常不满意";
      } else if (key === "max_name") {
        event.target.value = "非常满意";
      }
      if (key === "min_value") {
        event.target.value = 0;
        if (this.state.question_type_val === "max_diff") {
          event.target.value = parseInt(this.state.dataList.min_value);
        }
      } else if (key === "max_value") {
        event.target.value = 5;
        if (this.state.question_type_val === "max_diff") {
          event.target.value = parseInt(this.state.dataList.max_value);
        }
      } else if (key === "max_option") {
        event.target.value = this.state.dataList.answers.length || 0;
      } else if (key === "min_option") {
        event.target.value = this.state.dataList.answers.length ? 1 : 0;
      } else if (key === "min_time") {
        event.target.value = 0;
      }
      message.warning(`不可为空，所以恢复默认值～`, 2, () => { });
    }
    let value = parseInt(event.target.value);
    let cur_min_value = parseInt(this.state.dataList.min_value);
    let cur_max_value = parseInt(this.state.dataList.max_value);
    if (
      this.state.question_type_val === "score" ||
      this.state.question_type_val === "matrix_score"
    ) {
      if (key === "min_value") {
        if (value > 11 || value > cur_max_value) {
          event.target.value = this.state.beforeUpdateMin;
        }
      }
      if (key === "max_value") {
        if (value > 11 || value < cur_min_value) {
          event.target.value = this.state.beforeUpdateMax;
        }
      }
    } else if (
      this.state.question_type_val === "nps" ||
      this.state.question_type_val === "matrix_nps"
    ) {
      if (key === "min_value") {
        event.target.value = 1;
      }
      if (key === "max_value") {
        if (value > 100 || value < 1) {
          event.target.value = this.state.beforeUpdateMax;
        }
      }
    } else if (this.state.question_type_val === "multiple") {
      if (key === "max_option") {
        if (value > this.state.dataList.answers.length) {
          event.target.value = this.state.dataList.answers.length;
          message.warning(`最多选项不可以超过题目的选项`, 2, () => { });
        } else if (value < parseInt(this.state.dataList.min_option)) {
          event.target.value = this.state.dataList.max_option;
          message.warning(`最多选项不可以少于最少选项`, 2, () => { });
        }
      } else if (key === "min_option") {
        if (value > parseInt(this.state.dataList.max_option)) {
          event.target.value = this.state.dataList.min_option;
          message.warning(`最少选项不可以超过最多选项`, 2, () => { });
        }
      }
    } else if (this.state.question_type_val === "heatmap") {
      if (key === "max_option") {
        if (value > 30) {
          event.target.value = this.state.dataList.max_option
          message.warning(`最多点击次数不可以超过30次`, 2, () => { });
        } else if (value < parseInt(this.state.dataList.min_option)) {
          event.target.value = this.state.dataList.max_option;
          message.warning(`最多点击次数不可以少于最低点击次数`, 2, () => { });
        }
      } else if (key === "min_option") {
        if (value < 1) {
          event.target.value = this.state.dataList.min_option
          message.warning(`最少点击次数不可以小于1`, 2, () => { });
        } else if (value > parseInt(this.state.dataList.max_option)) {
          event.target.value = this.state.dataList.min_option;
          message.warning(`最少点击次数不可以超过最多点击次数`, 2, () => { });
        }
      }
    } else if (this.state.question_type_val === "matrix_multiple") {
      if (key === "max_option") {
        if (
          value >
          this.state.dataList.answers.filter(
            (item) => item.option_position === "left"
          ).length
        ) {
          event.target.value = this.state.dataList.answers.filter(
            (item) => item.option_position === "left"
          ).length;
          message.warning(`最多选项不可以超过题目的选项`, 2, () => { });
        }
        if (value < parseInt(this.state.dataList.min_option)) {
          event.target.value = this.state.dataList.max_option;
          message.warning(`最多选项不可以少于最少选项`, 2, () => { });
        }
      } else if (key === "min_option") {
        if (value > parseInt(this.state.dataList.max_option)) {
          event.target.value = this.state.dataList.min_option;
          message.warning(`最少选项不可以超过最多选项`, 2, () => { });
        }
      }
    } else if (this.state.question_type_val === "max_diff") {
      if (key === "min_value") {
        if (value < 2) {
          event.target.value = this.state.beforeUpdateMin;
          message.warning(`任务选项数限制在 2-n 范围内`, 2, () => { });
        } else if (value >= this.state.dataList.answers.length) {
          event.target.value = this.state.beforeUpdateMin;
          message.warning(`任务选项数应小于选项数量`, 2, () => { });
        } else if (parseFloat(value * this.state.dataList.max_value) < this.state.dataList.answers.length) {
          event.target.value = this.state.beforeUpdateMin;
          message.warning(`任务数选项数与任务数的乘积，应大于等于选项数量`, 2, () => { });
        }
      }
      if (key === "max_value") {
        if (value < 2) {
          event.target.value = this.state.beforeUpdateMax;
          message.warning(`任务数限制在2-100范围内`, 2, () => { });
        } else if (parseFloat(value * this.state.dataList.min_value) < this.state.dataList.answers.length) {
          event.target.value = this.state.beforeUpdateMax;
          message.warning(`任务数选项数与任务数的乘积，应大于等于选项数量`, 2, () => { });
        }
      }
    } else if (this.state.question_type_val === 'sort') {
      if (key === 'min_option') {
        if (value > this.state.dataList.answers.length) {
          event.target.value = this.state.dataList.answers.length;
          message.warning(`最少选项不可以超过题目的选项`, 2, () => { });
        } else if(value < 0) {
          event.target.value = 0;
          message.warning(`最少选项不可以小于0`, 2, () => { });
        }
      }
    }
    this.props.handleInput(
      key,
      event.target.value,
      this.state.currentQuestionIndex
    );
  }
  //验证数字
  validateNumber(event) {
    event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
    event.target.value = event.target.value.replace(/[^/.\d]/g, "");
    event.target.value = event.target.value.replace(".", "");
  }
  // 选择下拉框
  onSelectChange(next_selected_question_type) {
    // event 是 next_selected_question_type
    let current_question_type_val = getQuestionType(
      this.state.dataList.question_type
    );
    let next_selected_question_type_val = getQuestionType(
      next_selected_question_type
    );
    let tip_popup_msg = "";
    let showTipPopup = false;

    if (
      current_question_type_val === "single" ||
      current_question_type_val === "multiple" ||
      current_question_type_val === "proportion" ||
      current_question_type_val === "sort"
    ) {
      if (next_selected_question_type_val === "picture") {
        tip_popup_msg = "转换成图片题将丢失所有选项信息，是否继续？";
        showTipPopup = true;
      }
    } else if (
      current_question_type_val === "matrix_single" ||
      current_question_type_val === "matrix_multiple" ||
      current_question_type_val === "matrix_blank" ||
      current_question_type_val === "matrix_psm"
    ) {
      if (
        next_selected_question_type_val === "matrix_score" ||
        next_selected_question_type_val === "matrix_nps"
      ) {
        tip_popup_msg =
          "转换成该题将，原行选项保留，原列选项会被新增成行选项，是否继续？";
        showTipPopup = true;
      }
    } else if (
      current_question_type_val === "matrix_score" ||
      current_question_type_val === "matrix_nps" ||
      current_question_type_val === "matrix_blank"
    ) {
      if (
        next_selected_question_type_val === "matrix_single" ||
        next_selected_question_type_val === "matrix_multiple" ||
        next_selected_question_type_val === "matrix_blank" ||
        next_selected_question_type_val === "matrix_psm"
      ) {
        tip_popup_msg =
          "转换成该题，原行选项保留，列选项为默认选项，是否继续？";
        showTipPopup = true;
      }
    }

    this.setState(
      {
        showTipPopup: showTipPopup,
        tip_popup_msg: tip_popup_msg,
        next_selected_question_type: next_selected_question_type,
      },
      () => {
        if (!showTipPopup) {
          this.props.handleSelectOption(next_selected_question_type);
        }
      }
    );
  }
  // 获取题目类别
  getOptionList() {
    return this.state.option_list;
  }
  // 是否展示提示框
  canShowTipPopup(object) {
    let { showTipPopup, confirmed } = object;
    this.setState({ showTipPopup: showTipPopup }, () => {
      if (confirmed) {
        this.props.handleSelectOption(this.state.next_selected_question_type);
      }
    });
  }
  // 选项上下架
  handleActiveOption() {
    this.props.handleActiveOption(
      this.props.currentOptionIndex,
      this.state.currentQuestionIndex
    );
  }
  // 选项描述
  handleOptionInput(event) {
    this.props.handleOptionInput(
      event.target.value,
      this.props.currentOptionIndex,
      this.state.currentQuestionIndex
    );
  }
  // Topx
  handleTopInput(event) {
    this.props.handleTopInput(
      event.target.value,
      this.props.currentOptionIndex,
      this.state.currentQuestionIndex
    );
  }
  // 选项互斥
  handleMutexOption(key_arr) {
    this.props.handleMutexOption(
      key_arr,
      this.props.currentOptionIndex,
      this.state.currentQuestionIndex
    );
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  // 获取互斥列表
  getMutextOptionList() {
    let arr = [];
    for (let i = 0; i < this.state.dataList.answers.length; i++) {
      let obj = {
        key: i + 1,
        value: this.state.dataList.answers[i].name,
        disabled: false,
      };
      if (this.props.currentOptionIndex === i) {
        obj.disabled = true;
      }
      arr.push(obj);
    }
    return arr;
  }
  // 获取当前选项的索引值
  getCurrentOptionIndex(currentOptionIndex) {
    let current_question_type_val = getQuestionType(
      this.state.dataList.question_type
    );
    let has_matrix_list = ["matrix_single", "matrix_multiple", "matrix_blank", "matrix_psm"];

    let resultOptionIndex = currentOptionIndex;
    if (has_matrix_list.includes(current_question_type_val)) {
      let option_position =
        this.state.dataList.answers[currentOptionIndex].option_position;
      let option_position_index = -1;
      for (let i = 0; i < this.state.dataList.answers.length; i++) {
        if (
          this.state.dataList.answers[i].option_position === option_position
        ) {
          option_position_index++;
          if (i === currentOptionIndex) {
            break;
          }
        }
      }
      resultOptionIndex = option_position_index;
    }
    return resultOptionIndex;
  }
  // 其他选项描述
  isContentDisabled() {
    if (this.state.dataList.is_other === 1) {
      //题目
      if (this.state.dataList.answers[this.props.currentOptionIndex].is_other)
        return true;
    }
    return false;
  }
  // 计算任务数
  calTaskRage() {
    if (!this.state.dataList.answers?.length || !this.state.dataList.min_value) {
      return ''
    }
    let k = this.state.dataList.answers.length
    let m = this.state.dataList.min_value
    let range_1 = Math.floor(3 * (k / m))
    let range_2 = Math.floor(5 * (k / m))
    return range_1 + '-' + range_2
  }
  // 计算最小样本量
  calSampleSize() {
    if (!this.state.dataList.answers?.length || !this.state.dataList.min_value || !this.state.dataList.max_value) {
      return 0
    }
    let k = this.state.dataList.answers.length
    let m = this.state.dataList.min_value
    let t = this.state.dataList.max_value
    let size = Math.ceil((500 * k) / (m * t))
    return size
  }
  render() {
    let currentOptionIndex = this.props.currentOptionIndex;
    return (
      <>
        {!this.props.is_option_config ? (
          <div className="contain-modules">
            <div className="contain-modules-title">
              <div className="name">
                <span className="line"></span>
                <span className="text">
                  问题
                  {this.state.currentQuestionIndex === -1
                    ? ""
                    : this.state.currentQuestionIndex + 1}
                </span>
              </div>
            </div>

            {/* 非固定三项 */}
            <div
              className={
                this.has_fixed_list.includes(this.state.dataList.question_type)
                  ? "isHide"
                  : "setting-right-items"
              }
            >
              <div className="setting-status">问题类别</div>
              <div>
                <XmSelect
                  options={this.getOptionList.call(this)}
                  value={this.state.currentQuestionKey}
                  onChange={(e) => {
                    this.onSelectChange(e);
                  }}
                ></XmSelect>
              </div>
            </div>
            {/* 固定三项 */}
            <div
              className={
                this.has_fixed_list.includes(this.state.dataList.question_type)
                  ? "setting-right-items"
                  : "isHide"
              }
            >
              <div className="setting-status">问题类别</div>
              <div className="setting-status">
                {getQuestionTypeName(
                  getQuestionType(this.state.dataList.question_type)
                )}
              </div>
            </div>

            {/* 注意固定三项不可点击 */}
            <div
              className={
                this.has_fixed_list.includes(this.state.dataList.question_type)
                  ? "setting-fixed-item"
                  : ""
              }
            >
              <div className="setting-right-items">
                <div className="setting-status">是否必填</div>
                <img
                  src={
                    this.state.dataList.is_required === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_required")}
                />
              </div>
              <div className="setting-right-items">
                <div className="setting-status">是否可以返回上一题</div>
                <img
                  src={
                    this.state.dataList.is_back_allowed === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_back_allowed")}
                />
              </div>
              <div
                className={
                  this.is_option_random_list.includes(
                    this.state.question_type_val
                  )
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">是否选项乱序</div>
                <img
                  src={
                    this.state.dataList.is_option_random === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_option_random")}
                />
              </div>
              <div className="setting-right-items">
                <div className="setting-status">题目组</div>
                <div>
                  <input
                    placeholder="请输入"
                    className="setting-input"
                    type="text"
                    maxLength="3"
                    onBlur={this.handleInput.bind(this, "question_group")}
                    onInput={this.validateNumber.bind(this)}
                    defaultValue={this.state.dataList.question_group}
                    key={this.state.dataList.question_group}
                  />
                  <span className="is-min-time">组</span>
                </div>
              </div>
              <div className="setting-right-items">
                <div className="setting-status">是否组内乱序</div>
                <img
                  src={
                    this.state.dataList.is_within_group_random === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_within_group_random")}
                />
              </div>
              <div className="setting-right-items">
                <div className="setting-status">是否组间乱序</div>
                <img
                  src={
                    this.state.dataList.is_group_random === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_group_random")}
                />
              </div>
              <div className="setting-right-items">
                <div className="setting-status">题目上下架</div>
                <div>
                  <span className="item-is-active">
                    {this.state.dataList.is_question_active === 1
                      ? "(上架)"
                      : "(下架)"}
                  </span>
                  <img
                    src={
                      this.state.dataList.is_question_active === 1
                        ? cur_icon_on
                        : cur_icon_off
                    }
                    className="setting-right-icon"
                    alt=""
                    onClick={this.handleSwitchs.bind(
                      this,
                      "is_question_active"
                    )}
                  />
                </div>
              </div>
              <div
                className={
                  this.is_other_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">是否有[其他]</div>
                <img
                  src={
                    this.state.dataList.is_other === 1
                      ? cur_icon_on
                      : cur_icon_off
                  }
                  className="setting-right-icon"
                  alt=""
                  onClick={this.handleSwitchs.bind(this, "is_other")}
                />
              </div>
              <div className="setting-right-items">
                <div className="setting-status">最短答题时间</div>
                <div>
                  <input
                    placeholder="请输入"
                    className="setting-input"
                    type="text"
                    maxLength="3"
                    onBlur={this.handleInput.bind(this, "min_time")}
                    onInput={this.validateNumber.bind(this)}
                    defaultValue={this.state.dataList.min_time}
                    key={this.state.dataList.min_time}
                  />
                  <span className="is-min-time">秒</span>
                </div>
              </div>
              <div
                className={
                  this.has_value_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">
                  {this.state.question_type_val === "max_diff" ? `每个任务出现选项数` : "最小分值"}
                  {this.state.question_type_val === "max_diff" ? <span> 推荐3-5</span> : ""}
                </div>
                <input
                  placeholder="请输入"
                  className="setting-input"
                  type="text"
                  maxLength="3"
                  onBlur={this.handleInput.bind(this, "min_value")}
                  onInput={this.validateNumber.bind(this)}
                  defaultValue={this.state.dataList.min_value}
                  key={this.state.dataList.min_value}
                />
              </div>
              <div
                className={
                  this.has_value_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">
                  {this.state.question_type_val === "max_diff" ? `任务数` : "最大分值"}
                  {this.state.question_type_val === "max_diff" ? <span> 推荐{this.calTaskRage.call(this)}</span> : ""}
                </div>
                <input
                  placeholder="请输入"
                  className="setting-input"
                  type="text"
                  maxLength="3"
                  onBlur={this.handleInput.bind(this, "max_value")}
                  onInput={this.validateNumber.bind(this)}
                  defaultValue={this.state.dataList.max_value}
                  key={this.state.dataList.max_value}
                />
              </div>
              <div
                className={
                  this.state.question_type_val === "proportion"
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">
                  比重总和
                </div>
                <input
                  placeholder="请输入"
                  className="setting-input"
                  type="text"
                  maxLength="3"
                  onBlur={this.handleInput.bind(this, "max_value")}
                  onInput={this.validateNumber.bind(this)}
                  defaultValue={this.state.dataList.max_value}
                  key={this.state.dataList.max_value}
                />
              </div>

              <div
                className={
                  this.has_value_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">{this.state.question_type_val === "max_diff" ? "最差的标签" : "最小值名称"}</div>
                <input
                  placeholder="请输入名称"
                  className="setting-input"
                  type="text"
                  onBlur={this.handleInput.bind(this, "min_name")}
                  defaultValue={this.state.dataList.min_name}
                  key={this.state.dataList.min_name}
                />
              </div>
              <div
                className={
                  this.has_value_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">{this.state.question_type_val === "max_diff" ? "最好的标签" : "最大值名称"}</div>
                <input
                  placeholder="请输入名称"
                  className="setting-input"
                  type="text"
                  onBlur={this.handleInput.bind(this, "max_name")}
                  defaultValue={this.state.dataList.max_name}
                  key={this.state.dataList.max_name}
                />
              </div>
              <div
                className={
                  this.has_shelf_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">每行选项数量</div>
                <input
                  placeholder="请输入"
                  className="setting-input"
                  type="text"
                  onInput={this.validateNumber.bind(this)}
                  onBlur={this.handleInput.bind(this, "max_name")}
                  defaultValue={this.state.dataList.max_name}
                  key={this.state.dataList.max_name}
                />
              </div>
              {/* maxdiff */}
              {
                this.state.question_type_val === "max_diff" ? <div className="setting-right-items">
                  <div className="setting-status">推荐最小样本</div>
                  <div>{this.calSampleSize.call(this)}</div>
                </div> : ""
              }
              <div
                className={
                  this.has_option_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">
                  最少
                  {this.state.question_type_val === "matrix_multiple"
                    ? "行"
                    : ""}
                  {this.state.question_type_val === "heatmap"
                    ? "点击次数"
                    : "选项"}
                </div>
                <input
                  placeholder="请输入1-100"
                  className="setting-input"
                  type="text"
                  maxLength="3"
                  onBlur={this.handleInput.bind(this, "min_option")}
                  onInput={this.validateNumber.bind(this)}
                  defaultValue={this.state.dataList.min_option}
                  key={this.state.dataList.min_option}
                />
              </div>
              <div
                className={
                  this.has_option_list_1.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">
                  最多
                  {this.state.question_type_val === "matrix_multiple"
                    ? "行"
                    : ""}
                  {this.state.question_type_val === "heatmap"
                    ? "点击次数"
                    : "选项"}
                </div>
                <input
                  placeholder="请输入1-100"
                  className="setting-input"
                  type="text"
                  maxLength="3"
                  onBlur={this.handleInput.bind(this, "max_option")}
                  onInput={this.validateNumber.bind(this)}
                  defaultValue={this.state.dataList.max_option}
                  key={this.state.dataList.max_option}
                />
              </div>
            </div>
            <div className="setting-right-items">
              <div className="setting-status">问题逻辑</div>
              <span
                className="text cursor"
                onClick={this.logic_settings.bind(this)}
              >
                设置
              </span>
            </div>
            {/* <div className='setting_right_items'>
                        <div className='setting_status'>计算设置</div>
                        <span className='text cursor' onClick={this.calculation_settings.bind(this)}>设置</span>
                    </div> */}
          </div>
        ) : (
          <div className="contain-modules">
            <div className="contain-modules-title">
              <div className="name">
                <span className="line"></span>
                <span className="text">
                  问题
                  {this.state.currentQuestionIndex === -1
                    ? ""
                    : this.state.currentQuestionIndex + 1}
                </span>
              </div>
            </div>
            <div className="contain-modules-subtitle">
              选项
              {currentOptionIndex === -1
                ? ""
                : this.getCurrentOptionIndex(currentOptionIndex) + 1}
            </div>
            <div
              className={
                this.has_fixed_list.includes(this.state.dataList.question_type)
                  ? "setting-fixed-item"
                  : ""
              }
            >
              <div
                className={
                  this.is_option_active_list.includes(
                    this.state.question_type_val
                  )
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">选项上下架</div>
                <div>
                  <span className="item-is-active">
                    {this.state.dataList.answers[currentOptionIndex]
                      .is_option_active === 1
                      ? "(上架)"
                      : "(下架)"}
                  </span>
                  <img
                    src={
                      this.state.dataList.answers[currentOptionIndex]
                        .is_option_active === 1
                        ? cur_icon_on
                        : cur_icon_off
                    }
                    className="setting-right-icon"
                    alt=""
                    onClick={this.handleActiveOption.bind(this)}
                  />
                </div>
              </div>
              <div
                className={
                  this.has_content_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">选项描述</div>
                <input
                  placeholder="请输入选项描述"
                  className="setting-input setting-input-option"
                  type="text"
                  onChange={this.handleOptionInput.bind(this)}
                  value={
                    this.state.dataList.answers[currentOptionIndex].content
                  }
                  disabled={this.isContentDisabled.call(this)}
                />
              </div>
              <div
                className={
                  this.has_topx_list.includes(this.state.question_type_val)
                    ? "setting-right-items"
                    : "isHide"
                }
              >
                <div className="setting-status">选项对应分数</div>
                <input
                  placeholder="请输入选项对应分数"
                  className="setting-input setting-input-option"
                  type="text"
                  onChange={this.handleTopInput.bind(this)}
                  value={
                    this.state.dataList.answers[currentOptionIndex]
                      .option_position
                  }
                />
              </div>
              <div
                className={
                  this.has_topx_list.includes(this.state.question_type_val)
                    ? "setting-right-tip"
                    : "isHide"
                }
              >
                <div className="setting-right-tip-title">配置须知:</div>
                <div className="setting-right-tip-content">
                  <div>
                    <span>* </span>1. 如需要top/mean的统计方式，则根据选项数量
                    从最好到最差由大到小填入【选项对应分数】。例如，
                    假设有七个选项。非常好=7，很差=1。
                  </div>
                  <div>
                    <span>* </span>2.
                    如需要jar的统计方式，则【选项对应分数】之和
                    需要为0，并且中间选项的【选项对应分数】为0。例如，假设有5个选项，正好=0，有点浓=1，有点淡=-1，很浓=2，很淡=-2。
                  </div>
                </div>
              </div>
              <div
                className={
                  this.has_mutex_list.includes(this.state.question_type_val)
                    ? "setting-right-items setting-right-items-option"
                    : "isHide"
                }
              >
                <div className="setting-status">互斥设置</div>
                <div className="setting-select-option">
                  <XmSelect
                    mode="multiple"
                    placeholder="请进行选择"
                    onChange={(e) => {
                      this.handleMutexOption.call(this, e);
                    }}
                    allowClear
                    options={this.getMutextOptionList.call(this)}
                    value={
                      this.state.dataList.answers[currentOptionIndex].mutex
                    }
                    filterOption={(input, option) => {
                      return this.filterSelectOption.call(this, input, option);
                    }}
                  ></XmSelect>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showTipPopup ? (
          <XmTip
            tip_popup_msg={this.state.tip_popup_msg}
            canShowTipPopup={this.canShowTipPopup.bind(this)}
          ></XmTip>
        ) : (
          ""
        )}
      </>
    );
  }
}
