import React, {Component} from "react";
import {connect} from "react-redux";
import {getUser} from "../../redux/actions/user";
import "./home_page.scss";
import rank_1 from "../../images/home_page/rank_1.png";
import rank_2 from "../../images/home_page/rank_2.png";
import rank_3 from "../../images/home_page/rank_3.png";
import gender_0 from "../../images/home_page/gender_0.png";
import gender_1 from "../../images/home_page/gender_1.png";
import qrcode_1 from "../../images/home_page/qrcode_1.png";
import qrcode_2 from "../../images/home_page/qrcode_2.png";
import swiper_2 from "../../images/home_page/swiper_2.png";
import swiper_3 from "../../images/home_page/swiper_3.png";
import wish_1 from "../../images/home_page/wish_1.png";
import wish_2 from "../../images/home_page/wish_2.png";
import cur_icon_search from "../../images/emotion_analysis/cur_icon_search.png";
import RingChart from "./Ring_chart/ring_chart";
import NormInfo from "./Norm_info/norm_info";
import Loading from "../../components/Loading/loading";
import XmSelect from "../../components/Select/xm_select";
import DownloadBrief from "./Download_brief/download_brief";
import AboutUs from "./About_us/about_us";
import UpdataNotice from "./Updata_notice/updata_notice";
import BrandInfo from "../Login/brand_info";
import {
    getLoginType,
    getToken,
    getWXLoginCount,
    removeBrandInfoPopupStatus,
    removeWXLoginCount,
    setBrandInfoPopupStatus,
    setRefreshToken,
    setToken,
    setUserStatus,
    setWXLoginCount,
} from "../../util/auth";
import {getBrandAuthInfo, saveBrandAuthInfo} from "../../api/token";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import {A11y, Navigation, Pagination, Scrollbar} from "swiper";
import {
    getAnnouncementList,
    getCelebrityList,
    getCommonQuestionList,
    getResearchReportCategoryList,
    getResearchReportList,
    getWishList,
    getWishProductList,
    getWishStats,
} from "../../api/Home_page/home_page_api";

import {ConfigProvider, Empty, Image, Input, message, Pagination as Pagination2, Tooltip,} from "antd";
import zhCN from "antd/lib/locale/zh_CN";

class home_page extends Component {
    constructor(props) {
        super(props);
        // 初始化
        this.downloadListRef = React.createRef();
        this.brandInfoRef = React.createRef();
        this.updataNoticeRef = React.createRef();
        this.state = {
            showDownloadBrief: false,
            showAboutUs: false,
            showUpdataNotice: false,
            list: [],
            download_display_type_list: [{key: -1, value: "全部"}],
            download_display_type_val: -1,
            download_list: [], //下载列表
            download_list_formal: [], //下载列表
            download_list_current_page: 1, //当前页面
            download_list_interval: 7, //每页5个
            download_list_sum: 0, //总共多少个评论结果
            first_display_product_list: [], // 展示的第一个产品种草榜
            second_display_product_list: [], //展示的第二个产品种草榜
            second_display_product_list_formal: [],
            second_display_type_list: [{key: 0, value: "全部"}],
            second_display_type_val: 0,
            first_display_product_list_formal: [],
            first_display_type_list: [{key: 0, value: "全部"}],
            first_display_type_val: 0,
            first_display_title: "", // 展示的第一个产品种草榜
            second_display_title: "", //展示的第二个产品种草榜
            rank_talent_list: [], //心愿盒达人
            problem_list: [],
            swiper_content_list: [{name: ""}, {name: ""}],
            rank_img_arr: [rank_1, rank_2, rank_3],
            wish_current_date: "",
            wish_next_date: "",
            ring_data_list: {
                data: [
                    {name: "女性", value: 0, percent: 0},
                    {name: "男性", value: 0, percent: 0},
                ],
                sum: 0,
            },
            // new
            wish_id: -1,
            wish_id_prev: -1,
            wish_id_reprev: -1,
            future_wish_id: -1,
            loading: 2,
            loading_norm: 0,
            loading_category: 0,
            loadingText: "数据较大，加载中",
            wish_status: 1, // 1:没有41，40上线超过15天，39已下线 2:有41未上线，40上线超过15天，39已下线 3:有41上线未超过15天，40已下线，39已下线
            show_brand_info: false, //是否展示用户信息认证
            brand_info: {}, // 用户认证信息

            updata_notice_page: 1,
            updata_notice_total: 1,
            updata_notice_list: [],

            commont_question_page: 1,
            commont_question_total: 1,
            commont_question_list: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        // let login_type = getLoginType().toString()
        let login_type = getLoginType() ? String(getLoginType()) : "";
        this.getBrandAuthInfo(login_type);
        // 老用户可以获取内容
        this.getWishList();
        this.getCelebrityList();
        this.getResearchReportCategoryList();
        this.getUpdataNoticeList(1);
        this.getCommonQuestionList(1);
    }

    // 获取账户登录信息
    async getBrandAuthInfo(t) {
        try {
            const response = await getBrandAuthInfo({});
            let user_status = response.data.status; // 审核状态 1 新用户 2 审核中 3 老用户
            let brand_info = {
                first_name: response.data.first_name, //姓名
                phone: response.data.phone, //手机
                brand: response.data.brand, //品牌
                company: response.data.company, //公司
                department: response.data.department, //部门
                position: response.data.position, //职位
                // photo_verification: [{ filename: "", file: "" }]
                photo_verification: response.data.photo_verification, //照片
                desired_info: response.data.desired_info,
                comment: response.data.comment, //评论
                sms_code: response.data.sms_code,
                invite_code: response.data.invite_code, //邀请码 获取只能为空
                invite_type:
                    response.data.invite_type === 0 ? null : response.data.invite_type,
                inviter: response.data.inviter,
            };
            // 在刷新浏览器前，用户信息认证状态的路线,如果是手机，回填一个手机号
            if (!this.state.phone_number && t === "phone_login") {
                this.setState({
                    phone_number: brand_info.phone,
                });
            }
            setUserStatus(user_status);
            if (
                user_status === 1 &&
                t === "wx_login" &&
                Number(getWXLoginCount()) >= 1
            ) {
                //新用户且第一次登录
                localStorage.clear();
                window.location.href = "/login";
            } else if (user_status === 1 && t === "wx_login") {
                setWXLoginCount(1);
            }
            if (user_status === 3) {
                removeWXLoginCount();
                removeBrandInfoPopupStatus();
                this.props.getUser();
            } else {
                if (user_status === 4) {
                    message.warning(`用户信息审核不通过`, 2, () => {
                    });
                }
                setBrandInfoPopupStatus(1);
                this.setState(
                    {
                        show_brand_info: true,
                        loading: 0,
                    },
                    () => {
                        this.brandInfoRef.current.setBrandInfo({
                            login_type: t,
                            user_status: user_status,
                            brand_info: brand_info,
                        });
                    }
                );
            }
            return response;
        } catch (error) {
            message.error("获取账户登录信息失败");
            localStorage.clear();
            window.location.href = "/login";
            throw error;
        }
    }

    // 获取所有心愿的列表 取倒数两个
    async getWishList() {
        this.setState({
            loading: 4,
        });
        try {
            const response = await getWishList({});
            let wish_status = this.state.wish_status;
            let wish_list = JSON.parse(JSON.stringify(response.data.wish_list));
            let has_current_data = response.data.has_current_data;
            let future_wish = response.data.future_wish;
            let swiper_cur_wish = wish_list.pop();
            let swiper_prev_wish = wish_list.pop();
            let swiper_reprev_wish = wish_list.pop();
            let first_display_title = swiper_prev_wish.name;
            let second_display_title = swiper_reprev_wish.name;
            let swiper_content_list = [swiper_cur_wish, swiper_prev_wish]; //swiper
            if (has_current_data === 1 && future_wish === -1) {
                wish_status = 1;
                first_display_title = swiper_cur_wish.name;
                second_display_title = swiper_prev_wish.name;
            } else if (has_current_data === 1 && future_wish !== -1) {
                wish_status = 2;
                swiper_content_list = [swiper_prev_wish, swiper_cur_wish]; //swiper
            } else if (has_current_data === 0 && future_wish === -1) {
                wish_status = 3;
            }

            let wish_id = swiper_cur_wish.id;
            let wish_id_prev = swiper_prev_wish.id;
            let wish_id_reprev = swiper_reprev_wish.id;

            this.setState(
                {
                    wish_id: wish_id,
                    wish_id_prev: wish_id_prev,
                    wish_id_reprev: wish_id_reprev,
                    first_display_title: first_display_title,
                    second_display_title: second_display_title,
                    swiper_content_list: swiper_content_list,
                    wish_status: wish_status,
                    future_wish_id: future_wish,
                    loading: this.state.loading - 1,
                },
                () => {
                    this.getWishStats();
                    this.getWishProductListFirst();
                    this.getWishProductListSecond();
                }
            );
        } catch (error) {
            this.setState({loading: 0}, () => {
                message.error("后台发生错误");
            });
            throw error;
        }
    }

    // 获取心愿图表
    async getWishStats() {
        let wish_id = this.state.wish_id;
        if (this.state.wish_status === 2) {
            wish_id = this.state.wish_id_prev;
        }
        try {
            const response = await getWishStats({
                wish_id: wish_id,
                future_wish: this.state.future_wish_id,
            });
            // if (!response) return
            let ring_data_list = {
                sum: response.data.user_count,
                data: [
                    {
                        name: "女性",
                        value: response.data.female_count,
                        percent: response.data.female_percent,
                    },
                    {
                        name: "男性",
                        value: response.data.male_count,
                        percent: response.data.male_percent,
                    },
                ],
            };
            let wish_current_date =
                this.getDateNoZero(response.data.start_time) +
                "-" +
                this.getDateNoZero(response.data.end_time);
            let wish_next_date = -1;
            if (response.data.future_wish_time !== -1) {
                wish_next_date = this.getDateNoZero(response.data.future_wish_time);
            }
            this.setState({
                wish_current_date,
                wish_next_date: wish_next_date,
                ring_data_list,
                loading: this.state.loading - 1,
            });
        } catch (error) {
            this.setState(
                {
                    loading: 0,
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    // 处理日期 9.26
    getDateNoZero(date) {
        return date
            .slice(5)
            .split("-")
            .map((ele) => parseInt(ele))
            .join(".");
    }

    // 根据心愿获取产品列表信息
    async getWishProductListFirst() {
        let wish_id_tmp = this.state.wish_id;
        if (this.state.wish_status === 1) {
            wish_id_tmp = this.state.wish_id;
        } else {
            wish_id_tmp = this.state.wish_id_prev;
        }

        try {
            const response = await getWishProductList({
                wish_id: wish_id_tmp,
            });
            if (response.status === 204) {
                this.setState({
                    first_display_product_list: [],
                    first_display_type_list: [{key: 0, value: "全部"}],
                    loading: this.state.loading - 1,
                });
                message.warning("暂无种草榜数据", 2, () => {
                });
            } else {
                let product_list = response.data.product_list;
                let first_display_product_list = [];
                let first_display_product_list_formal = [];
                let first_display_type_list = [{key: 0, value: "全部"}];
                let first_display_type_num = 1;
                let deduplicateMap = new Map();
                for (let i = 0; i < product_list.length; i++) {
                    let obj = {
                        img: product_list[i].product_image,
                        name:
                            product_list[i].brand_name + "-" + product_list[i].product_title,
                        test_num: product_list[i].comment_ppl_count,
                        like_rate: product_list[i].like_rate,
                        highlight: product_list[i].highlight,
                        major_category: product_list[i].major_category,
                    };
                    let type_obj = {
                        key: first_display_type_num,
                        value: product_list[i].major_category,
                    };
                    if (!deduplicateMap.has(type_obj.value)) {
                        first_display_type_num += 1;
                        first_display_type_list.push(type_obj);
                        deduplicateMap.set(type_obj.value);
                    }
                    first_display_product_list.push(obj);
                    first_display_product_list_formal.push(obj);
                }
                this.setState({
                    first_display_product_list: first_display_product_list,
                    first_display_product_list_formal: first_display_product_list_formal,
                    first_display_type_list: first_display_type_list,
                    loading: this.state.loading - 1,
                });
            }
        } catch (error) {
            this.setState(
                {
                    loading: 0,
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    async getWishProductListSecond() {
        let wish_id_tmp = this.state.wish_id_prev;
        if (this.state.wish_status === 1) {
            wish_id_tmp = this.state.wish_id_prev;
        } else {
            wish_id_tmp = this.state.wish_id_reprev;
        }
        try {
            const response = await getWishProductList({
                wish_id: wish_id_tmp,
            });
            if (response.status === 204) {
                this.setState({
                    second_display_product_list: [],
                    second_display_type_list: [{key: 0, value: "全部"}],
                    loading: this.state.loading - 1,
                });
                message.warning("暂无种草榜数据", 2, () => {
                });
            } else {
                let product_list = response.data.product_list;
                let second_display_product_list = [];
                let second_display_product_list_formal = [];
                let second_display_type_list = [{key: 0, value: "全部"}];
                let second_display_type_num = 1;
                let deduplicateMap = new Map();
                for (let i = 0; i < product_list.length; i++) {
                    let obj = {
                        img: product_list[i].product_image,
                        name:
                            product_list[i].brand_name + "-" + product_list[i].product_title,
                        test_num: product_list[i].comment_ppl_count,
                        like_rate: product_list[i].like_rate,
                        highlight: product_list[i].highlight,
                        major_category: product_list[i].major_category,
                    };
                    let type_obj = {
                        key: second_display_type_num,
                        value: product_list[i].major_category,
                    };
                    if (!deduplicateMap.has(type_obj.value)) {
                        second_display_type_num += 1;
                        second_display_type_list.push(type_obj);
                        deduplicateMap.set(type_obj.value);
                    }
                    second_display_product_list.push(obj);
                    second_display_product_list_formal.push(obj);
                }
                this.setState({
                    second_display_product_list: second_display_product_list,
                    second_display_product_list_formal:
                    second_display_product_list_formal,
                    second_display_type_list: second_display_type_list,
                    loading: this.state.loading - 1,
                });
            }
        } catch (error) {
            this.setState(
                {
                    loading: 0,
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    // 获取明星用户
    async getCelebrityList() {
        try {
            const response = await getCelebrityList({});
            let rank_talent_list = response.data.user_list;
            this.setState({
                rank_talent_list,
            });
        } catch (error) {
            this.setState(
                {
                    // loading: 2
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    // 获取下载文件列表类别
    async getResearchReportCategoryList() {
        try {
            const response = await getResearchReportCategoryList({});
            if (response.status === 204) {
                this.setState({
                    download_display_type_list: [{key: -1, value: "全部"}],
                    download_list: -1,
                });
                message.warning("暂无下载列表数据", 2, () => {
                });
            } else {
                let download_display_type_list = JSON.parse(
                    JSON.stringify(this.state.download_display_type_list)
                );
                for (
                    let i = 0;
                    i < response.data.research_report_category_list.length;
                    i++
                ) {
                    let type_obj = {
                        key: response.data.research_report_category_list[i].category_id,
                        value: response.data.research_report_category_list[i].name,
                    };
                    download_display_type_list.push(type_obj);
                }
                this.setState(
                    {
                        download_display_type_list: download_display_type_list,
                    },
                    () => {
                        this.getResearchReportList();
                    }
                );
            }
        } catch (error) {
            this.setState(
                {
                    // loading: 2
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    // 将is_recommend排在前面
    getDownloadList(download_list) {
        let cmp = function (a, b) {
            return b.is_recommend - a.is_recommend;
        };
        return download_list.sort(cmp);
    }

    // 获取文件下载列表
    async getResearchReportList() {
        try {
            const response = await getResearchReportList({
                category_id:
                    this.state.download_display_type_val === -1
                        ? ""
                        : this.state.download_display_type_val,
            });
            if (response.status === 204) {
                this.setState({
                    download_list: [],
                    download_list_formal: [],
                    download_list_sum: 0,
                });
            } else {
                let download_list = this.getDownloadList(response.data);
                this.setState({
                    download_list: download_list.slice(
                        0,
                        this.state.download_list_interval
                    ),
                    download_list_formal: download_list,
                    download_list_sum: download_list.length,
                });
            }
        } catch (error) {
            this.setState(
                {
                    // loading: 2
                },
                () => {
                    message.error("后台发生错误");
                }
            );
            throw error;
        }
    }

    // 跳转到消费者研究的新界面
    goFirstSwiper() {
        let wish_id = this.state.wish_id;
        if (this.state.wish_status === 2) {
            wish_id = this.state.wish_id_prev;
        }
        this.props.history.push(`/consumer_research?wish_id=${wish_id}`);
    }

    // 跳转到派送计划
    goSecondSwiper() {
        this.props.history.push(`/dispatch_list`);
    }

    // 跳转查看使用指南
    goThirdSwiper() {
        this.setState({
            showAboutUs: true,
        });
    }

    // 跳转下载列表页
    goDownloadList(item) {
        this.setState(
            {
                showDownloadBrief: true,
            },
            () => {
                this.downloadListRef.current.setDowloadBrief(item);
            }
        );
    }

    // 立即下载文件
    handleDownload(url) {
        let fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.target = "_blank";
        fileLink.click();
    }

    //是否显示
    canShowDownloadBrief(flag) {
        this.setState({
            showDownloadBrief: flag,
        });
    }

    //是否显示
    canShowAboutUs(flag) {
        this.setState({
            showAboutUs: flag,
        });
    }

    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return (
            option.children.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
        );
    }

    // 筛选种草棒
    selectRankChange(key, option, t) {
        let cur_index = parseInt(option.key);

        let cur_major_category =
            this.state[t + "_display_type_list"][cur_index].value;
        let display_product_list = JSON.parse(
            JSON.stringify(this.state[t + "_display_product_list_formal"])
        );

        if (cur_index > 0) {
            display_product_list = display_product_list.filter((element) => {
                return cur_major_category === element.major_category;
            });
        }
        this.setState({
            [t + "_display_product_list"]: display_product_list,
        });
    }

    //筛选下载列表
    selectDownloadChange(key, option, t) {
        let cur_index = parseInt(option.key);
        let cur_key = parseInt(key);
        this.setState(
            {
                download_display_type_val: key,
            },
            () => {
                this.getResearchReportList();
            }
        );
    }

    // 搜索下载列表按钮 传父
    handleSearch(t, event) {
        if (!t) return false;
        if (!event) return false;
        let search_text = event.target.value.trim();
        if (search_text === "") {
            this.setState({
                download_list: this.state.download_list_formal,
            });
        } else {
            let download_list = [];
            for (let i = 0; i < this.state.download_list_formal.length; i++) {
                if (
                    this.state.download_list_formal[i].description.indexOf(
                        search_text
                    ) !== -1
                ) {
                    download_list.push(this.state.download_list_formal[i]);
                } else if (
                    this.state.download_list_formal[i].name.indexOf(search_text) !== -1
                ) {
                    download_list.push(this.state.download_list_formal[i]);
                }
            }
            this.setState({
                download_list: download_list,
            });
        }
    }

    // 切换页面
    onchangeDownloadListPagination(page, pageSize) {
        let start = (page - 1) * this.state.download_list_interval;
        let end = start + this.state.download_list_interval;
        let tmp = this.state.download_list_formal.slice(start, end);
        setTimeout(() => {
            this.setState({download_list: tmp});
        });
        setTimeout(() => {
            this.setState({download_list_current_page: page});
        });
    }

    // 用户信息认证
    handleBrandInfo(obj) {
        if (!obj.is_confirm) {
            // 按的取消的键
            removeBrandInfoPopupStatus();
            this.setState({
                show_brand_info: obj.show_brand_info,
            });
            return;
        }
        // 按的是提交
        this.setState(
            {
                brand_info: obj.brand_info || {},
            },
            () => {
                if (obj.brand_info) {
                    this.saveBrandAuthInfo();
                }
            }
        );
    }

    // 保存用户信息认证信息
    async saveBrandAuthInfo() {
        try {
            const response = await saveBrandAuthInfo({
                first_name: this.state.brand_info.first_name, //姓名
                phone: this.state.brand_info.phone, //手机
                brand: this.state.brand_info.brand, //品牌
                company: this.state.brand_info.company, //公司
                department: this.state.brand_info.department, //部门
                position: this.state.brand_info.position, //职位
                // photo_verification: [{ filename: "", file: "" }]
                photo_verification: this.state.brand_info.photo_verification, //照片
                desired_info: this.state.brand_info.desired_info,
                comment: this.state.brand_info.comment, //评论
                sms_code: this.state.brand_info.sms_code,
                invite_code: this.state.brand_info.invite_code || "",
                invite_type:
                    this.state.brand_info.invite_type === null
                        ? ""
                        : this.state.brand_info.invite_type,
                inviter: this.state.brand_info.inviter || "",
            });
            setUserStatus(response.data.status);
            if (String(getToken()) !== response.data.access) {
                setToken(response.data.access);
                setRefreshToken(response.data.refresh);
            }
            if (response.data.status === 2) {
                message.warning(`审核中`, 2, () => {
                });
                this.brandInfoRef.current.setBrandInfo({
                    login_type: getLoginType(),
                    user_status: response.data.status,
                    brand_info: this.state.brand_info,
                });
            } else {
                this.setState({
                    show_brand_info: false,
                });
            }
            return response;
        } catch (err) {
            if (err.response?.data?.detail) {
                message.error(`${err.response.data.detail}`, 2, () => {
                });
            } else {
                message.error(`后台发生错误`, 2, () => {
                });
            }
            throw err;
        }
    }

    // 获取更新公告列表
    getUpdataNoticeList(page) {
        let param = {
            page,
            count: 2,
        };
        getAnnouncementList(param).then(
            (res) => {
                let data = res.data;
                console.log(data.data);
                for (let i in data.data) {
                    data.data[i].descriptionCopy = data.data[i].description;
                    data.data[i].description = data.data[i].description.replace(/\<.[^<>]*\>/g, '');
                }
                this.setState({
                    updata_notice_total: data.total,
                    updata_notice_page: page,
                    updata_notice_list: data.data || [],
                });
            },
            (err) => {
                this.setState({
                    updata_notice_page: 1,
                    updata_notice_total: 1,
                    updata_notice_list: [],
                });
            }
        );
    }

    // 获取常见问题列表
    getCommonQuestionList(page) {
        let param = {
            page,
            count: 2,
        };
        getCommonQuestionList(param).then(
            (res) => {
                let data = res.data;
                for (let i in data.data) {
                    data.data[i].descriptionCopy = data.data[i].description;
                    data.data[i].description = data.data[i].description.replace(/\<.[^<>]*\>/g, '');
                }
                this.setState({
                    commont_question_total: data.total,
                    commont_question_page: page,
                    commont_question_list: data.data || [],
                });
            },
            (err) => {
                this.setState({
                    commont_question_page: 1,
                    commont_question_total: 1,
                    commont_question_list: [],
                });
            }
        );
    }

    // 点击更新公告
    oClick(e, type) {
        this.setState(
            {
                showUpdataNotice: true,
            },
            () => {

                this.updataNoticeRef.current.setUpdataNotice(e, type);
            }
        );
    }

    //
    canShowUpdataNotice() {
        this.setState({
            showUpdataNotice: false,
        });
    }

    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className="evaluation-icon-search"/>
        );
        return (
            <div className="container home-container">
                {this.state.loading <= 0 &&
                this.state.loading_norm <= 0 &&
                this.state.loading_category <= 0 ? (
                    ""
                ) : (
                    <Loading loadingText={this.state.loadingText}></Loading>
                )}
                <div
                    className={
                        this.state.showDownloadBrief ||
                        this.state.showAboutUs ||
                        this.state.showUpdataNotice
                            ? "isHide"
                            : "home"
                    }
                >
                    <div className="top-row">
                        <div className="swiper-card-wrapper">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={20}
                                slidesPerView={1}
                                // navigation
                                pagination={{clickable: true}}
                                // scrollbar={{ draggable: true }}
                                onSwiper={(swiper) => {
                                }}
                                onSlideChange={() => {
                                }}
                            >
                                <SwiperSlide>
                                    <div
                                        className="swiper-card"
                                        onClick={this.goFirstSwiper.bind(this)}
                                    >
                                        <div className="swiper-bg">
                                            <img
                                                src={this.state.swiper_content_list[0].wish_image}
                                                alt=""
                                            />
                                        </div>
                                        <div className="swiper-content-wrapper">
                                            <div className="swiper-title">本期心愿活动</div>
                                            <div className="swiper-title">
                                                {this.state.swiper_content_list[0].name}
                                            </div>
                                            <div className="swiper-content">
                                                {this.state.swiper_content_list[0].content}
                                            </div>
                                            <div className="swiper-btn">→</div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="swiper-card"
                                        onClick={this.goSecondSwiper.bind(this)}
                                    >
                                        {this.state.wish_status === 2 ? (
                                            <div>
                                                <div className="swiper-bg">
                                                    <img
                                                        src={this.state.swiper_content_list[1].wish_image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="swiper-content-wrapper">
                                                    <div className="swiper-title">下期心愿活动</div>
                                                    <div className="swiper-title">
                                                        {this.state.swiper_content_list[1].name}
                                                    </div>
                                                    <div className="swiper-content">
                                                        {this.state.swiper_content_list[1].content}
                                                    </div>
                                                    <div className="swiper-btn">→</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="swiper-bg">
                                                    <img src={swiper_2} alt=""/>
                                                    <div className="swiper-content-wrapper">
                                                        <div className="swiper-title">敬请期待</div>
                                                        <div className="swiper-title"></div>
                                                        <div className="swiper-content"></div>
                                                        <div className="swiper-btn">→</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="swiper-card"
                                        onClick={this.goThirdSwiper.bind(this)}
                                    >
                                        <div className="swiper-bg">
                                            <img src={swiper_3} alt=""/>
                                        </div>
                                        <div className="swiper-content-wrapper">
                                            <div className="swiper-title">合作流程</div>
                                            <div className="swiper-content">
                                                与心愿盒 Match Box 合作
                                            </div>
                                            <div className="swiper-btn">
                                                →{/* <NavLink to="/home_page/about_us">→</NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="chart-card">
                            <div className="chart-title">本期心愿盒许愿人数</div>

                            <div className="wish-chart-container">
                                <RingChart
                                    single_id="ring_chart"
                                    dataList={this.state.ring_data_list}
                                ></RingChart>
                            </div>
                            <div className="wish-content-wrapper">
                                <div className="wish">
                                    <div className="wish-img">
                                        <img src={wish_1} alt=""/>
                                    </div>
                                    <div className="wish-text-wrapper">
                                        <div className="wish-title">本期心愿</div>
                                        <div className="wish-date-wrapper">
                                            <div className="wish-date">
                                                {this.state.wish_current_date}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wish">
                                    <div className="wish-img">
                                        <img src={wish_2} alt=""/>
                                    </div>
                                    <div className="wish-text-wrapper">
                                        <div className="wish-title">下期预计</div>
                                        <div className="wish-date-wrapper">
                                            {this.state.wish_next_date === -1 ? (
                                                ""
                                            ) : (
                                                <div className="wish-date">
                                                    {this.state.wish_next_date}
                                                </div>
                                            )}
                                            <div className="wish-text">
                                                {this.state.wish_next_date === -1 ? "敬请期待" : "开启"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rank-row">
                        <div className="rank-card rank-product-card">
                            <div className="rank-top-wrapper">
                                <div className="rank-top-title">
                                    {`${this.state.first_display_title} 种草榜`}
                                </div>
                                <div className="rank-top-select">
                                    <XmSelect
                                        dropdownClassName="rank-type-dropdown"
                                        showSearch
                                        filterOption={(input, option) => {
                                            return this.filterSelectOption.call(this, input, option);
                                        }}
                                        placeholder="请选择"
                                        defaultValue={this.state.first_display_type_val}
                                        key={this.state.first_display_type_val}
                                        options={this.state.first_display_type_list}
                                        onChange={(e, index) => {
                                            this.selectRankChange(e, index, "first");
                                        }}
                                    ></XmSelect>
                                </div>
                            </div>
                            {this.state.first_display_product_list.length === 0 ? (
                                <div className="rank-content-empty">
                                    <ConfigProvider locale={zhCN}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                    </ConfigProvider>
                                </div>
                            ) : (
                                <div className="rank-content-wrapper">
                                    {this.state.first_display_product_list.map((item, index) => {
                                        return (
                                            <div>
                                                <div
                                                    className={`rank-content-row ${
                                                        item.highlight > 0
                                                            ? "rank-content-row-highlight"
                                                            : ""
                                                    }`}
                                                    key={index}
                                                >
                                                    <div className="rank-content-row-left">
                                                        <div className="rank-sort">
                                                            {index <= 2 ? (
                                                                <img
                                                                    src={this.state.rank_img_arr[`${index}`]}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <span>{index + 1}</span>
                                                            )}
                                                        </div>
                                                        <div className="rank-img rank-img-product">
                                                            <ConfigProvider locale={zhCN}>
                                                                <Image
                                                                    src={item.img}
                                                                    alt=""
                                                                    width={42}
                                                                    height={42}
                                                                />
                                                            </ConfigProvider>
                                                        </div>

                                                        <div className="rank-title-wrapper">
                                                            <Tooltip placement="bottom" title={item.name}>
                                                                <div className="rank-name">{item.name}</div>
                                                            </Tooltip>
                                                            <div className="rank-test-num-wrapper">
                                <span className="rank-test-num">
                                  {item.test_num}
                                </span>
                                                                <span>人已评测</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rank-content-row-right">
                                                        <div className="rank-like-rate">
                                                            {item.like_rate}
                                                        </div>
                                                        <div className="rank-like-rate-text">种草率</div>
                                                    </div>
                                                </div>
                                                <div className={"rank-content-line"}></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="rank-card rank-product-card">
                            <div className="rank-top-wrapper">
                                <div className="rank-top-title">
                                    {`${this.state.second_display_title} 种草榜`}
                                </div>
                                <div className="rank-top-select">
                                    <XmSelect
                                        dropdownClassName="rank-type-dropdown"
                                        showSearch
                                        filterOption={(input, option) => {
                                            return this.filterSelectOption.call(this, input, option);
                                        }}
                                        placeholder="请选择"
                                        defaultValue={this.state.second_display_type_val}
                                        key={this.state.second_display_type_val}
                                        options={this.state.second_display_type_list}
                                        onChange={(e, index) => {
                                            this.selectRankChange(e, index, "second");
                                        }}
                                    ></XmSelect>
                                </div>
                            </div>
                            {this.state.second_display_product_list.length === 0 ? (
                                <div className="rank-content-empty">
                                    <ConfigProvider locale={zhCN}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                    </ConfigProvider>
                                </div>
                            ) : (
                                <div className="rank-content-wrapper">
                                    {this.state.second_display_product_list.map((item, index) => {
                                        return (
                                            <div>
                                                <div
                                                    className={`rank-content-row ${
                                                        item.highlight > 0
                                                            ? "rank-content-row-highlight"
                                                            : ""
                                                    }`}
                                                    key={index}
                                                >
                                                    <div className="rank-content-row-left">
                                                        <div className="rank-sort">
                                                            {index <= 2 ? (
                                                                <img
                                                                    src={this.state.rank_img_arr[`${index}`]}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <span>{index + 1}</span>
                                                            )}
                                                        </div>
                                                        <div className="rank-img rank-img-product">
                                                            <ConfigProvider locale={zhCN}>
                                                                <Image
                                                                    src={item.img}
                                                                    alt=""
                                                                    width={42}
                                                                    height={42}
                                                                />
                                                            </ConfigProvider>
                                                        </div>
                                                        <div className="rank-title-wrapper">
                                                            <Tooltip placement="bottom" title={item.name}>
                                                                <div className="rank-name">{item.name}</div>
                                                            </Tooltip>
                                                            <div className="rank-test-num-wrapper">
                                <span className="rank-test-num">
                                  {item.test_num}
                                </span>
                                                                <span>人已评测</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rank-content-row-right">
                                                        <div className="rank-like-rate">
                                                            {item.like_rate}
                                                        </div>
                                                        <div className="rank-like-rate-text">种草率</div>
                                                    </div>
                                                </div>
                                                <div className={"rank-content-line"}></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="rank-card rank-talent-card">
                            <div className="rank-title">心愿盒达人</div>
                            <div className="rank-content-wrapper">
                                {this.state.rank_talent_list.map((item, index) => {
                                    return (
                                        <div>
                                            <div className="rank-content-row" key={index}>
                                                <div className="rank-content-row-left">
                                                    <div className="rank-sort">
                                                        {index <= 2 ? (
                                                            <img
                                                                src={this.state.rank_img_arr[`${index}`]}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <span>{index + 1}</span>
                                                        )}
                                                    </div>
                                                    <div className="rank-img">
                                                        <ConfigProvider locale={zhCN}>
                                                            <Image
                                                                src={item.profile_pic}
                                                                alt=""
                                                                width={38}
                                                                height={38}
                                                            />
                                                        </ConfigProvider>
                                                    </div>
                                                    <div className="rank-title-wrapper">
                                                        <div className="rank-name-wrapper">
                                                            <Tooltip placement="bottom" title={item.nickname}>
                                                                <div className="rank-name">{item.nickname}</div>
                                                            </Tooltip>
                                                            <div className="rank-gender">
                                                                {item.gender === "女" ? (
                                                                    <img src={gender_0} alt=""/>
                                                                ) : (
                                                                    <img src={gender_1} alt=""/>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="rank-info-wrapper">
                                                            <span>{`${item.age} 丨 ${item.occupation}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rank-content-row-right">
                                                    <div className="rank-test-num-wrapper">
                                                        <div className="rank-like-rate">
                                                            {item.total_received_product}
                                                        </div>
                                                        <div className="rank-like-rate-text">评测产品</div>
                                                    </div>
                                                    <div className="rank-test-word-wrapper">
                                                        <div className="rank-like-rate">
                                                            {item.avg_word_count}
                                                        </div>
                                                        <div className="rank-like-rate-text">
                                                            平均评测字数/件
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"rank-content-line"}></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="like-row">
                        <NormInfo></NormInfo>
                    </div>
                    <div className="third-row">
                        <div className="download-card">
                            <div className="download-top-wrapper">
                                <div className="download-title">市场分析报告</div>
                                <div className="download-title-right-wrapper">
                                    <div className="download-search-wrapper">
                                        <Input
                                            prefix={prefix}
                                            placeholder="搜索关键词"
                                            onPressEnter={this.handleSearch.bind(
                                                this,
                                                "download_search"
                                            )}
                                            onBlur={this.handleSearch.bind(this, "download_search")}
                                        />
                                    </div>
                                    <div className="download-top-select">
                                        <XmSelect
                                            dropdownClassName="download-type-dropdown"
                                            showSearch
                                            filterOption={(input, option) => {
                                                return this.filterSelectOption.call(
                                                    this,
                                                    input,
                                                    option
                                                );
                                            }}
                                            placeholder="请选择"
                                            defaultValue={this.state.download_display_type_val}
                                            key={this.state.download_display_type_val}
                                            options={this.state.download_display_type_list}
                                            onChange={(e, index) => {
                                                this.selectDownloadChange(e, index, "download");
                                            }}
                                        ></XmSelect>
                                    </div>
                                    <div
                                        className={
                                            this.state.download_list_sum > 5
                                                ? "download-pagination-footer"
                                                : "isHide"
                                        }
                                    >
                                        <ConfigProvider locale={zhCN}>
                                            <Pagination2
                                                current={this.state.download_list_current_page}
                                                defaultCurrent={1}
                                                size={"small"}
                                                defaultPageSize={this.state.download_list_interval}
                                                total={this.state.download_list_sum}
                                                onChange={this.onchangeDownloadListPagination.bind(
                                                    this
                                                )}
                                                showSizeChanger={false}
                                            />
                                        </ConfigProvider>
                                    </div>
                                </div>
                            </div>

                            <div className="download-content-wrapper">
                                {this.state.download_list.map((item, index) => {
                                    return (
                                        <div className="download-content-row" key={index}>
                                            <div
                                                className="download-content-row-left"
                                                onClick={this.goDownloadList.bind(this, item)}
                                            >
                                                <div className="download-name-wrapper">
                                                    <div className="download-name-dot">·</div>
                                                    <div className="download-name">{item.name}</div>
                                                    {item.is_recommend ? (
                                                        <div className="download-recommend">置顶</div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="download-brief">{item.description}</div>
                                            </div>
                                            <div className="download-content-row-right">
                                                <div className="download-date">{item.upload_time}</div>
                                                <div
                                                    className="download-btn"
                                                    onClick={this.handleDownload.bind(
                                                        this,
                                                        item.location
                                                    )}
                                                >
                                                    立即下载 ↓
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="third-row-right">
                            <div className="qrcode-card">
                                <div className="qrcode-title">小程序二维码</div>
                                <div className="qrcode-content-wrapper">
                                    <div className="qrcode-wrapper">
                                        <div className="qrcode-pic">
                                            <img src={qrcode_1} alt=""/>
                                        </div>
                                        <div className="qrcode-text">微信小程序</div>
                                    </div>
                                    <div className="qrcode-wrapper">
                                        <div className="qrcode-pic">
                                            <img src={qrcode_2} alt=""/>
                                        </div>
                                        <div className="qrcode-text">微信公众号</div>
                                    </div>
                                </div>
                            </div>
                            <div className="update-card">
                                <div className="update-title download-pagination-footer">
                                    <div className="update-title-text">更新公告</div>
                                    <Pagination2
                                        current={this.state.updata_notice_page}
                                        defaultCurrent={1}
                                        size={"small"}
                                        defaultPageSize={2}
                                        total={this.state.updata_notice_total}
                                        onChange={this.getUpdataNoticeList.bind(this)}
                                        showSizeChanger={false}
                                    />
                                </div>
                                <div className="update-content-wrapper">
                                    {this.state.updata_notice_list.map((item, index) => {
                                        return (
                                            <div className="update-content-row" key={index}
                                                 onClick={this.oClick.bind(this, item, 1)}>
                                                <div className="update-content-row-left">
                                                    <div className="update-name-wrapper">
                                                        <div className="update-name">{item.name}</div>
                                                    </div>
                                                    <div className="update-brief">{item.description}</div>
                                                </div>
                                                <div className="update-content-row-right">
                                                    <div className="update-date">{item.upload_time}</div>
                                                </div>

                                                <div className="update-dialog">
                                                    <iframe
                                                        className="ifr"
                                                        frameborder="0"
                                                        src={item.location}
                                                    ></iframe>
                                                    <div
                                                        className="mosk"
                                                        onClick={this.oClick.bind(this, item, 1)}
                                                    ></div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="update-card">
                                <div className="update-title download-pagination-footer">
                                    <div className="update-title-text">常见问题</div>
                                    <Pagination2
                                        current={this.state.commont_question_page}
                                        defaultCurrent={1}
                                        size={"small"}
                                        defaultPageSize={2}
                                        total={this.state.commont_question_total}
                                        onChange={this.getCommonQuestionList.bind(this)}
                                        showSizeChanger={false}
                                    />
                                </div>
                                <div className="update-content-wrapper">
                                    {this.state.commont_question_list.map((item, index) => {
                                        return (
                                            <div
                                                className="update-content-row"
                                                key={index}
                                                onClick={this.oClick.bind(this, item, 2)}
                                            >
                                                <div className="update-content-row-left">
                                                    <div className="update-name-wrapper">
                                                        <div className="update-name">{item.name}</div>
                                                    </div>
                                                    <div className="update-brief">{item.description}</div>
                                                </div>
                                                <div className="update-content-row-right">
                                                    <div className="update-date">{item.upload_time}</div>
                                                </div>

                                                <div className="update-dialog">
                                                    <iframe
                                                        className="ifr"
                                                        frameborder="0"
                                                        src={item.location}
                                                    ></iframe>
                                                    <div
                                                        className="mosk"
                                                        onClick={this.oClick.bind(this, item, 2)}
                                                    ></div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showDownloadBrief ? (
                    <DownloadBrief
                        ref={this.downloadListRef}
                        canShowDownloadBrief={this.canShowDownloadBrief.bind(this)}
                    ></DownloadBrief>
                ) : (
                    ""
                )}
                {this.state.showAboutUs ? (
                    <AboutUs canShowAboutUs={this.canShowAboutUs.bind(this)}></AboutUs>
                ) : (
                    ""
                )}
                {this.state.showUpdataNotice ? (
                    <UpdataNotice
                        ref={this.updataNoticeRef}
                        canShowUpdataNotice={this.canShowUpdataNotice.bind(this)}
                    />
                ) : (
                    ""
                )}
                <div className={this.state.show_brand_info ? "" : "isHide"}>
                    <BrandInfo
                        ref={this.brandInfoRef}
                        handleBrandInfo={this.handleBrandInfo.bind(this)}
                    ></BrandInfo>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        user: state.user,
    }), //映射状态
    {getUser} //映射操作状态的方法
)(home_page);
