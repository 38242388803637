import React, { Component } from 'react'
import { connect } from 'react-redux'
import './question_info.scss';
import { Input } from 'antd';
import cur_icon_edit from '../../../../images/cur_icon_edit.png'
import project_icon_del from '../../../../images/project_icon_del.png'
import project_icon_del_active from '../../../../images/project_icon_del_active.png'
import project_icon_copy from '../../../../images/project_icon_copy.png'
import project_icon_copy_active from '../../../../images/project_icon_copy_active.png'
import project_icon_move from '../../../../images/project_icon_move.png'
import BlankInfo from './Question_type_info/Blank_info/blank_info'
import NpsInfo from './Question_type_info/Nps_info/nps_info'
import ProportionInfo from './Question_type_info/Proportion_info/proportion_info'
import SingleInfo from './Question_type_info/Single_info/single_info'
import SortInfo from './Question_type_info/Sort_info/sort_info'
import ScoreInfo from './Question_type_info/Score_info/score_info'
import MatrixScoreInfo from './Question_type_info/Matrix_score_info/matrix_score_info'
import MatrixNpsInfo from './Question_type_info/Matrix_nps_info/matrix_nps_info'
import MatrixSingleInfo from './Question_type_info/Matrix_single_info/matrix_single_info'
import PictureInfo from './Question_type_info/Picture_info/picture_info'
import MaxdiffInfo from './Question_type_info/Maxdiff_info/maxdiff_info'
import HeatmapInfo from './Question_type_info/Heatmap_info/heatmap_info'
import QuestionEditPopup from './Question_edit_popup/question_edit_popup'
import EditNode from '../../../EditNode/edit_node';
import XmUpload from '../../../Upload_pic/xm_upload';
import XmModel from '../../../Xm_model/Xm_model'
import { questionType, getQuestionTypeValue, getQuestionType, getQuestionTypeName } from "../Question_rules/question_type_rule"
import { question_authority_obj } from "../question_authority_configuration"
// import Upload from "../../../Upload/Upload";
import { Upload, Modal, Image, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { PlusOutlined } from '@ant-design/icons';
import { Draggable } from "react-beautiful-dnd";


let that = ''
class question_info extends Component {
    constructor(props) {
        super(props);
        this.has_fixed_list = [11, 12, 13] // 最大小值 名称
        this.state = {
            dataList: {},
            question_index: 0,
            modelContent: "确定删除该题目吗？",
            showModel: false,
            modelTitle: "删除提示",
            showQuestionEditPopup: false,//是否显示编辑题目的弹窗
            canRichText: false
        }
    }
    //根据不同题目类型展示
    chooseQuestionInfo() {
        let currentOptionIndex = this.props.currentOptionIndex
        if (this.state.question_index !== this.props.currentQuestionIndex) {
            currentOptionIndex = -1
        }
        let type = this.state.dataList.question_type
        if (type === 3 || type === 18) { //简答题
            return <BlankInfo></BlankInfo>
        } else if (type === 7 || type === 11) { //滑条腿
            return <NpsInfo dataList={this.state.dataList}></NpsInfo>
        } else if (type === 6) { //比重题
            return <ProportionInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                dataList={this.state.dataList}></ProportionInfo>
        } else if (type === 1 || type === 2 || type === 12 || type === 13) { // 单选腿/多选题/种草/购买意愿
            return <SingleInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleOptionUp={this.handleOptionUp.bind(this)}
                handleOptionDown={this.handleOptionDown.bind(this)}
                dataList={this.state.dataList}></SingleInfo>
        } else if (type === 4) {
            return <SortInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                dataList={this.state.dataList}></SortInfo>
        } else if (type === 5) {
            return <ScoreInfo
                dataList={this.state.dataList}></ScoreInfo>
        } else if (type === 8 || type === 9 || type === 16 || type === 22) { // 矩阵单选,矩阵多选，PSM
            return <MatrixSingleInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddMatrixOption={this.handleAddMatrixOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                dataList={this.state.dataList}></MatrixSingleInfo>
        } else if (type === 14) {
            return <MatrixScoreInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                dataList={this.state.dataList}></MatrixScoreInfo>
        } else if (type === 15) {
            return <MatrixNpsInfo
                currentOptionIndex={currentOptionIndex}
                handleSetOption={this.handleSetOption.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                handleActiveOption={this.handleActiveOption.bind(this)}
                dataList={this.state.dataList}></MatrixNpsInfo>
        } else if (type === 10) {
            return <PictureInfo
                addQuestionPic={this.addQuestionPic.bind(this)}
                deleteQuestionPic={this.deleteQuestionPic.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                dataList={this.state.dataList}></PictureInfo>
        } else if (type === 19) {
            return <MaxdiffInfo
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                dataList={this.state.dataList}></MaxdiffInfo>
        } else if (type === 20) {
            return <HeatmapInfo
                handleQuestionOption={this.handleQuestionOption.bind(this)}
                addQuestionPic={this.addQuestionPic.bind(this)}
                deleteQuestionPic={this.deleteQuestionPic.bind(this)}
                handleQuestionOptionName={this.handleQuestionOptionName.bind(this)}
                handleDeleteOption={this.handleDeleteOption.bind(this)}
                handleAddOption={this.handleAddOption.bind(this)}
                addOptionPic={this.addOptionPic.bind(this)}
                deleteOptionPic={this.deleteOptionPic.bind(this)}
                dataList={this.state.dataList}></HeatmapInfo>
        }
        return type
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                question_index: props.index,
            }
        }
        return null;
    }
    componentDidMount() {
        that = this
        this._isMounted = true
        // if (this._isMounted) {
        //     this.toInitChart()
        //     setTimeout(() => {
        //         this.toInitFoot()
        //     })
        // }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            // this.toInitChart()
            // setTimeout(() => {
            //     this.toInitFoot()
            // })
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 答卷配置
    editQuestionInfos() {
        this.props.editQuestionInfo(this.state.question_index)
    }

    //删除问题
    deleteQuestions() {
        this.props.deleteQuestion(this.state.question_index)
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        if (flag) {
            this.deleteQuestions()
        }
        this.setState({
            showModel: val,
        })
    }
    // copy这个题目
    handleCopy() {
        this.props.handleCopy(this.state.question_index)
    }
    // 编辑题目 修改答卷题目
    onChangeEditor(obj) {
        this.props.handleQuestionName(obj.value, this.state.question_index)
    }
    // 爸爸heatmap编辑选项
    handleQuestionOption(answers) {
        this.props.handleQuestionOption(this.state.question_index, answers)
    }
    // 爸爸编辑选项名称
    handleQuestionOptionName(option_index, option_value, option_position) {
        this.props.handleQuestionOptionName(this.state.question_index, option_index, option_value, option_position)
    }
    // 爸爸删除选项
    handleDeleteOption(option_index, option_position) {
        this.props.handleDeleteOption(this.state.question_index, option_index, option_position)
    }
    // 爸爸选项上移
    handleOptionUp(option_index, option_position) {
        this.props.handleOptionUp(this.state.question_index, option_index, option_position)
    }
    // 爸爸选项下移
    handleOptionDown(option_index, option_position) {
        this.props.handleOptionDown(this.state.question_index, option_index, option_position)
    }
    // 爸爸设置选项
    handleSetOption(option_index, option_position) {
        this.props.handleSetOption(this.state.question_index, option_index, option_position)
    }
    // 爸爸添加选项
    handleAddOption(option_index) {
        this.props.handleAddOption(this.state.question_index, option_index)
    }
    // 爸爸添加行选项-矩阵
    handleAddMatrixOption(option_position) {
        this.props.handleAddMatrixOption(option_position, this.state.question_index)
    }
    // 爸爸添加选项图片
    addOptionPic(option_index, image, filename, option_position) {
        this.props.addOptionPic(this.state.question_index, option_index, image, filename, option_position)
    }
    // 爸爸选项上下架
    handleActiveOption(option_index) {
        this.props.handleActiveOption(option_index, this.state.question_index)
    }
    // 爸爸删除选项图片
    deleteOptionPic(option_index, option_position) {
        this.props.deleteOptionPic(this.state.question_index, option_index, option_position)
    }
    // 添加题目图片
    addQuestionPic(image, filename) {
        this.props.addQuestionPic(image, filename, this.state.question_index)
    }
    // 删除题目图片
    deleteQuestionPic() {
        this.props.deleteQuestionPic(this.state.question_index)
    }
    // 控制是否显示富文本编辑问题题目
    handleQuestionEditPopupStatus = (obj) => {
        this.props.handleQuestionName(obj.question_name, this.state.question_index)
        this.setState({
            showQuestionEditPopup: obj.showQuestionEditPopup
        })
    }
    // 点击编辑题目 显示popup并传值
    editQuestionTitle(question_name) {
        this.setState({
            canRichText: true,
            showQuestionEditPopup: true
        })
    }
    // 种草题固定为第一道 基础共创不可改变前三题的位置
    canDragBtn() {
        if (!this.has_fixed_list.includes(this.state.dataList.question_type)) {
            return true
        }
        if (this.props.is_native && question_authority_obj.rule_1[this.props.user.account_type]) {
            // 种草提固定为第一道题
            if (this.state.dataList.question_type === 12) {
                return false
            }
        }
        if (this.props.is_native && question_authority_obj.rule_2[this.props.user.account_type]) {
            // 基础共创不可改变前三题的位置
            if (this.has_fixed_list.includes(this.state.dataList.question_type)) {
                return false
            }
        }
        return true
    }
    render() {
        return (
            <div>
                <Draggable
                    key={this.state.question_index}
                    draggableId={"draggable-" + this.state.question_index}
                    index={this.state.question_index}
                    isDragDisabled={!this.canDragBtn()}
                >
                    {(provided, snapshot) => {
                        return <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                    ? ""
                                    : "none",
                            }}
                        >
                            <div className={`question-info-list ${this.props.currentQuestionIndex === this.state.question_index ? "question-info-list-current" : ""}`} onClick={this.editQuestionInfos.bind(this)}>
                                <img className='setting_edit_icon' src={cur_icon_edit} alt="" onClick={this.editQuestionInfos.bind(this)} />
                                <div className="info-list-top">
                                    {/* 题目标题 */}
                                    <div className='info-list-title'>

                                        <div className={`info-list-title-sign ${this.state.dataList.is_required === 1 ? "isRed" : "isTransparent"}`}>*</div>
                                        <div>{this.state.question_index + 1}</div>
                                        <div className="info-list-title-type">{getQuestionTypeName(getQuestionType(this.state.dataList.question_type))}-</div>
                                        <div className={this.has_fixed_list.includes(this.state.dataList.question_type) ? "isHide" : ""}>
                                            <div className="edit-question-title-wrap">
                                                <EditNode value={this.state.dataList.name} canRichText={this.state.canRichText} onChange={this.onChangeEditor.bind(this)} className="edit-question-title"></EditNode>
                                                <div className="edit-question-btn" onClick={this.editQuestionTitle.bind(this, this.state.dataList.name)}>富文本编辑</div>
                                            </div>
                                        </div>
                                        <div className={this.has_fixed_list.includes(this.state.dataList.question_type) ? "" : "isHide"}>
                                            <div className="edit-question-title-wrap">
                                                {this.state.dataList.name}
                                            </div>
                                        </div>
                                        {
                                            this.state.dataList.question_type === 10 || this.state.dataList.question_type === 20 ? "" :
                                                <XmUpload className="upload-question-pic"
                                                    image_name={this.state.dataList.image.val}
                                                    handleDeletePic={this.deleteQuestionPic.bind(this)}
                                                    handleUploadPic={this.addQuestionPic.bind(this)}></XmUpload>
                                        }

                                    </div>
                                    {/* 题目标题删除 */}
                                    <div className="setting-icon">
                                        {/* <div className='setting-icon'> */}
                                        {
                                            this.canDragBtn() ? <div className='setting-icon-move' {...provided.dragHandleProps}>
                                                <img src={project_icon_move} alt="" />
                                            </div> : <div className='setting-icon-move setting-icon-not-move'>
                                                <img src={project_icon_move} alt="" />
                                            </div>
                                        }
                                        {/* <div className={setting-icon-move} {...provided.dragHandleProps}>
                                            <img src={project_icon_move} alt="" />
                                        </div> */}

                                        <div className="setting-icon-copy" onClick={this.handleCopy.bind(this)} >
                                            <img src={project_icon_copy} alt="" className="icon-copy" />
                                            <img src={project_icon_copy_active} alt="" className="icon-copy-active" />
                                        </div>
                                        <div className="setting-icon-delete" onClick={this.handleModel.bind(this, true, false)} >
                                            <img src={project_icon_del} alt="" className="icon-delete" />
                                            <img src={project_icon_del_active} alt="" className="icon-delete-active" />
                                        </div>
                                    </div>
                                </div>
                                {/* 题目内容 */}
                                <div className='info-list-content'>
                                    {this.chooseQuestionInfo.call(this)}
                                </div>
                            </div>
                        </div>
                    }}
                </Draggable>
                {
                    this.state.showQuestionEditPopup ? <QuestionEditPopup
                        handleQuestionEditPopupStatus={this.handleQuestionEditPopupStatus}
                        currentQuestionIndex={this.props.currentQuestionIndex}
                        question_name={this.state.dataList.name}
                    ></QuestionEditPopup> : ""
                }
                <div className='hello'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
            </div >
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(question_info)
