import React, { Component } from 'react'
import './edit_node.scss';

export default class edit_node extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            canRichText: false,
        }
    }


    onChange() {
        var html = ""
        if (this.props.canRichText) {
            html = this.ref.current.innerHTML
        } else {
            html = this.ref.current.innerText
        }
        // html = html.replace(/[\n]+/g, "")  // 先可以有换行
        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange({ value: html });
        }
        this.lastHtml = html;
    }

    componentDidMount() {
        let reg = /<[^<>]+>/g
        if (reg.test(this.props.value)) {
            this.setState({
                canRichText: true
            })
        }
    }
    shouldComponentUpdate(nextProps) {
        if (this.props.canRichText) {
            return nextProps.value !== this.ref.current.innerHTML;
        } else {
            return nextProps.value !== this.ref.current.innerText;
        }
    }

    componentDidUpdate(preProps, preState) {
        if (this.props.canRichText || this.state.canRichText) {
            if (this.props.value !== this.ref.current.innerHTML) {
                this.ref.current.innerHTML = this.props.value;
                this.setState({ canRichText: false })
            }
        } else {
            if (this.props.value !== this.ref.current.innerText) {
                this.ref.current.innerText = this.props.value;
            }
        }
    }

    render() {
        const { className, value } = this.props;
        return (

            <p contentEditable="true"
                className={className}
                dangerouslySetInnerHTML={{ __html: value }}
                ref={this.ref}
                onInput={this.onChange.bind(this)}
                onBlur={this.onChange.bind(this)}
                placeholder={this.props.placeholder}
            ></p>

        )
    }
}
